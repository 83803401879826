import { useCallback, useEffect, useState } from 'react'
import { UserContext, UserPreferences } from 'scala/src/types'
import { Moises as MoisesNew } from '../../lib/cli'
import { config } from '../../config'
import { UserToken } from '../../context/types'

type DemoPlaylistPreferences = UserPreferences['demoPlaylist']

interface UseDemoPlaylistPreferences {
  setDemoPlaylistPreferences: (
    data: NonNullable<DemoPlaylistPreferences>
  ) => Promise<boolean>
  demoPlaylistPreferences: DemoPlaylistPreferences
  shouldHide: boolean
  handleHide: () => void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useDemoPlaylistPreferences = ({
  user,
  userToken
}: {
  user: UserContext
  userToken: UserToken
}): UseDemoPlaylistPreferences => {
  const [demoPlaylistPreferences, setDemoPlaylistPreferencesState] =
    useState<DemoPlaylistPreferences>(user?.preferences?.demoPlaylist)

  MoisesCLI.auth(userToken)

  useEffect(() => {
    setDemoPlaylistPreferencesState(user?.preferences?.demoPlaylist)
  }, [user?.preferences?.demoPlaylist])

  const setDemoPlaylistPreferences = useCallback(
    async (data: NonNullable<DemoPlaylistPreferences>): Promise<any> => {
      setDemoPlaylistPreferencesState(data)
      return MoisesCLI.updateDemoPlaylistPreferences(data)
    },
    []
  )

  const shouldHide = demoPlaylistPreferences?.hideFromLibrary || false

  const handleHide = useCallback(async () => {
    await setDemoPlaylistPreferences({ hideFromLibrary: true })
  }, [setDemoPlaylistPreferences])

  return {
    demoPlaylistPreferences,
    setDemoPlaylistPreferences,
    shouldHide,
    handleHide
  }
}
