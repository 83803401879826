import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ScalaContext } from 'scala'
import { useContext, useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import { Context } from '../../../context'

interface UseSubscribePending {
  interval?: number
  forcePending?: boolean
}

export const useSubscribePending = ({
  interval = 5000,
  forcePending = false
}: UseSubscribePending): void => {
  const { i18n } = useLingui()
  const {
    toast: { add: addToast }
  } = useContext(Context)
  const { silentRetry, loadingUserInfo, user } = useContext(ScalaContext)
  const hasSubscriptionPending =
    user?.subscription?.details?.pending || forcePending
  const [started, setStarted] = useState(forcePending)

  useEffect(() => {
    if (started && !loadingUserInfo && !hasSubscriptionPending) {
      addToast({
        closable: true,
        type: 'success',
        description: i18n._(t`subscribe.plan.state.success`),
        icon: null
      })
    }
  }, [i18n, started, loadingUserInfo, hasSubscriptionPending, addToast])

  useInterval(
    async () => {
      await silentRetry()
      setStarted(true)
    },
    hasSubscriptionPending ? interval : null
  )
}
