import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, FormInput, Icon } from 'scala'
import React, { ReactElement } from 'react'
import styles from './reset-password.module.scss'

interface ResetPasswordProps {
  error: string | null
  loading: boolean
  email: string
  errorEmail: boolean
  goPageSignIn(): void
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onBlur(): void
}

export const ResetPassword = ({
  error,
  loading,
  email,
  errorEmail,
  goPageSignIn,
  onChangeEmail,
  onSubmit,
  onBlur
}: ResetPasswordProps): ReactElement => {
  const { i18n } = useLingui()

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        {i18n._(t`email.reset.password.description`)}
      </p>

      <form onSubmit={onSubmit} onBlur={onBlur}>
        <FormInput
          value={email}
          error={!!(error || errorEmail)}
          className={styles.input}
          onChange={onChangeEmail}
          id="email_address_textbox"
          placeholder={i18n._(t`account.settings.user.email`)}
          icon={<Icon name="email" className={styles.icon} />}
        >
          {error ? <p className={styles.labelError}>{error}</p> : null}
        </FormInput>

        <Button
          small
          radius={1}
          type="submit"
          className={styles.button}
          disabled={loading}
          id="send_button"
          icon={
            loading ? (
              <Icon name="loader" animation="spin" />
            ) : (
              <Icon name="arrow" className={styles.iconArrowBack} />
            )
          }
          title={
            loading
              ? `${i18n._(t`actions.send.loading`)}...`
              : i18n._(t`actions.send.instructions`)
          }
        />
      </form>

      <Button
        small
        light
        radius={1}
        transparent
        id="back_button"
        onClick={goPageSignIn}
        className={styles.button}
        icon={<Icon name="arrow" />}
        title={i18n._(t`actions.back.login`)}
      />
    </div>
  )
}
