import React from 'react'
import classnames from 'classnames'
import { UserAvatarProps } from '../../types'
import { Icon } from '../icon'
import styles from './user-avatar.module.scss'

const isActive: any = ({
  pathname,
  active
}: {
  pathname: string
  active: boolean
}) => active || pathname === '/settings'

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  active = false,
  currentPathName
}) => {
  return (
    <div
      className={classnames(styles.avatar, {
        [styles.active]: isActive({ pathname: currentPathName, active })
      })}
    >
      {(user && user.profilePictureUrl && (
        <img alt="" className={styles.image} src={user.profilePictureUrl} />
      )) || <Icon className={styles.icon} name="user" />}
    </div>
  )
}
