import { TaskOperations } from '../../../types'

export const isOperation = (
  operations: TaskOperations[],
  operation: 'STARTED' | 'QUEUED' | 'FAILED'
): boolean => {
  if (!operations || !operations.length) {
    return false
  }

  const mainOperations = operations.filter(
    (op: TaskOperations) =>
      op.name.toLowerCase().includes('separate') ||
      op.name.toLowerCase().includes('mastering') ||
      op.name.toLowerCase().includes('denoiser')
  )

  if (
    mainOperations.find(
      (op: TaskOperations) => op.status.toUpperCase() === 'COMPLETED'
    )
  ) {
    return false
  }

  return !!mainOperations.find(
    (op: TaskOperations) => op.status.toUpperCase() === operation
  )
}
