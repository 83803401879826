import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useEffectOnce } from 'react-use'
import styles from './content.module.scss'

interface Props {
  children?: React.ReactNode
  Sidebar?: React.ReactNode
  error?: boolean
  loading?: boolean
}

export const Content: React.FC<Props> = ({
  children,
  Sidebar,
  error,
  loading
}) => {
  const [canShowSidebar, setCanShowSidebar] = useState(false)
  useEffectOnce(() => setCanShowSidebar(true))

  return (
    <div className={styles.container}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.sidebar}>
          {canShowSidebar &&
            Sidebar &&
            React.isValidElement(Sidebar) &&
            React.cloneElement(Sidebar as React.ReactElement<any>, {
              error,
              loading
            })}
        </div>

        <div className={styles.content}>{children}</div>
      </DndProvider>
    </div>
  )
}
