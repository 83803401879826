import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import { SwitchTask } from '../../../task-library/switch-task'
import { HeaderSectionUpload } from '../header-section-upload'
import styles from './nav-track-type.module.scss'
import { NavTrackTypeProps } from './types'

export const NavTrackType: React.FC<NavTrackTypeProps> = ({
  className,
  title,
  credits,
  items,
  onClickCredits
}) => {
  return (
    <div className={classnames(className, styles.container)}>
      {title ? <HeaderSectionUpload title={title} /> : null}
      {credits ? (
        <button
          type="button"
          onClick={onClickCredits}
          className={styles.credits}
        >
          {credits}{' '}
          <Icon name="info" width={16} height={16} className={styles.icon} />
        </button>
      ) : null}

      {items && items.length > 1 ? (
        <SwitchTask items={items} className={styles.switch} />
      ) : null}
    </div>
  )
}
