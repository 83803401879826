import React from 'react'
import classnames from 'classnames'
import { Icon } from 'scala'
import styles from './nav-user-error.module.scss'

interface Props {
  className?: string
  title: string
  small?: boolean
}

export const NavUserError: React.FC<Props> = ({ className, title, small }) => (
  <div className={classnames(className, styles.container)}>
    <div className={styles.iconContainer}>
      <Icon name="alert" className={styles.icon} />
    </div>

    {!small && <p className={styles.title}>{title}</p>}
  </div>
)
