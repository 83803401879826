import { useState } from 'react'
import { useIsExternalUrlValid } from '../../../misc/use-is-external-url-valid'
import { FileURL, LocalFile, TaskFile } from './types'

interface UseTaskInput {
  setLocalFile(file: LocalFile): void
  localFile: LocalFile
  setUrlExternal(url: FileURL): void
  urlExternal: FileURL
  isExternalUrlValid: boolean
  externalUrlErrorCode: string | null
  fileName: string | null
  setFileName(fileName: string): void
  taskFile: TaskFile
  setTaskFile(file: TaskFile): void
}

export const useTaskFile = (): UseTaskInput => {
  const [fileName, setFileName] = useState<string | null>(null)
  const [urlExternal, setUrlExternal] = useState<FileURL>(null)
  const [localFile, setLocalFile] = useState<LocalFile>(null)

  const [taskFile, setTaskFile] = useState<TaskFile>(null)

  const { isValid: isExternalUrlValid, error: externalUrlErrorCode } =
    useIsExternalUrlValid(urlExternal || '')

  return {
    // file from user computer
    setLocalFile,
    localFile,

    fileName,
    setFileName,

    // url from external website
    setUrlExternal,
    urlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,

    // either local file or external url
    taskFile,
    setTaskFile
  }
}
