import { Icon } from 'scala'
import React from 'react'
import styles from './track-detail.module.scss'

export interface TrackDetailProps {
  title: string
  filename: string
}

export const TrackDetail: React.FC<TrackDetailProps> = ({
  title,
  filename
}) => (
  <div className={styles.container}>
    <p className={styles.title}>{title}</p>

    <div className={styles.info}>
      <Icon name="music" />
      <p>{filename}</p>
    </div>
  </div>
)
