import * as Sentry from '@sentry/nextjs'
import { useAsyncFn } from 'react-use'
import { useContext } from 'react'
import { Context } from 'scala'
import { config } from '../../../config'
import { Moises as MoisesNew } from '../../../lib/cli'
import { useFirebaseToken } from '../../authentication'
import { CreateTaskOperation, CreateTasks } from './types'

export * from './types'

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useCreateTask = (): CreateTasks => {
  const { user } = useContext(Context)
  const userToken = useFirebaseToken()

  const [state, createTask] = useAsyncFn(
    async ({ file, operations }: CreateTaskOperation) => {
      MoisesCLI.auth(userToken)

      try {
        const result = await MoisesCLI.createTask({
          taskFile: {
            ...file,
            provider: file.provider.toUpperCase()
          },
          operations: operations.map((op) => ({
            name: op.id,
            params: op.params
          }))
        })
        return result
      } catch (e: any) {
        Sentry.captureMessage('Upload: error on create task', {
          level: 'error',
          extra: {
            userId: user?.id,
            error: e
          }
        })
        throw new Error(e)
      }
    },
    [userToken]
  )

  return {
    ...state,
    createTask
  }
}
