import * as Sentry from '@sentry/nextjs'
import { useContext, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { Context } from 'scala'
import { moisesSDK } from '../../../lib/firebase'

export const useUploadExternalUrl = (): any => {
  const { user } = useContext(Context)
  const [cancelExternalUrlUploadSource, setCancelExternalUrlUploadSource] =
    useState(null)

  const [state, uploadExternalUrl] = useAsyncFn(
    async (externalUrl: string, title?: string) => {
      if (!externalUrl) return null

      try {
        const result = await moisesSDK.upload.externalUrl({
          url: externalUrl,
          setCancelSource: setCancelExternalUrlUploadSource
        })

        if (title) {
          result.name = title
        }

        return result
      } catch (e: any) {
        Sentry.captureMessage('Upload: error on upload external url', {
          level: 'error',
          extra: {
            userId: user?.id,
            error: e
          }
        })
        throw new Error(e)
      }
    },
    []
  )

  return {
    ...state,
    uploadExternalUrl,
    cancelExternalUrlUploadSource
  }
}
