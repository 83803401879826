import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Context as ContextScala, Icon, Loading } from 'scala'
import { useWindowSize } from 'react-use'
import styles from './sidebar.module.scss'
import { LogoMoises } from '../../../components/sidebar/logo-moises'
import { Button } from '../../../components/sidebar/button'
import { Context } from '../../../context'
import { PlaylistsSidebar } from '../../playlist/playlists'
import { NavUserError } from '../../../components/sidebar/nav-user-error'
import { NavUser } from '../nav-user'
import { usePlanCowntdown } from '../../../hooks/plans/use-plan-pro-countdown'

interface Props {
  className?: string
  error?: boolean
  loading?: boolean
}

interface NavFilters {
  title: string
  icon: string
  beta?: string
  active?: boolean
  onClick?(): void
}

export const Sidebar: React.FC<Props> = ({
  className,
  error,
  loading
}): ReactElement => {
  const { i18n } = useLingui()
  const { width } = useWindowSize()
  const { user } = useContext(ContextScala)
  const { pathname, asPath, push } = useRouter()
  const {
    modalGetApp: { onOpen: onOpenModal }
  } = useContext(Context)

  const isDenoiserEnabled = user?.featureFlags?.denoiser || false
  const isComposerEnabled = user?.featureFlags?.composer || false
  const isPlaylistEnabled = user?.featureFlags?.playlist || false
  const isVoiceStudioEnabled = user?.featureFlags?.webNewPricingPage || false

  const isCollapsed = useMemo(
    () =>
      width < 950 ||
      pathname.includes('/subscribe') ||
      pathname.includes('/pricing') ||
      pathname.includes('/player') ||
      pathname.includes('/editor') ||
      pathname.includes('/library/[taskId]'),
    [pathname, width]
  )
  const bannerCountdown = usePlanCowntdown({
    disabled: isCollapsed
  })

  const onGoLibrary = useCallback(() => push('/library'), [push])

  const items = useMemo<NavFilters[]>(
    () =>
      [
        {
          title: i18n._(t`task.label.separate`),
          id: 'track_separation_button',
          icon: 'spliter',
          active: asPath === '/library/',
          onClick: () => push('/library')
        },
        isVoiceStudioEnabled
          ? {
              title: 'Voice Studio',
              id: 'voice_studio_button',
              icon: 'user-voice',
              active: pathname.includes('/voice'),
              onClick: () => push('/voice')
            }
          : null,
        {
          title: i18n._(t`task.label.master`),
          id: 'track_mastering_button',
          icon: 'mastering',
          active: asPath === '/library/?filter=master',
          onClick: () => push('/library/?filter=master')
        },
        isDenoiserEnabled
          ? {
              title: i18n._(t`task.label.denoiser`),
              id: 'track_denoiser_button',
              icon: 'denoiser',
              beta: i18n._(t`beta`),
              active: asPath === '/library/?filter=denoiser',
              onClick: () => push('/library/?filter=denoiser')
            }
          : null,
        isComposerEnabled
          ? {
              title: i18n._(t`lyric_writer`),
              id: 'lyric_writer_tab_button',
              icon: 'songwriting',
              active: pathname.includes('/lyric-writer'),
              onClick: () => push('/lyric-writer')
            }
          : null,
        {
          title: 'Plugins',
          id: 'plugins_button',
          icon: 'widget',
          onClick: () => onOpenModal()
        }
      ].filter(Boolean) as NavFilters[],
    [
      push,
      onOpenModal,
      i18n,
      asPath,
      pathname,
      isDenoiserEnabled,
      isComposerEnabled,
      isVoiceStudioEnabled
    ]
  )

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.collapsed]: isCollapsed
      })}
    >
      <LogoMoises small={isCollapsed} className={styles.logo} />

      <div className={styles.content}>
        <div className={styles.nav}>
          {items.map((props) => (
            <Button
              key={props.title}
              small={isCollapsed}
              disabled={loading}
              title={props.title}
              beta={props?.beta}
              onClick={props.onClick}
              active={props?.active && !loading}
              icon={<Icon width={20} height={20} name={props.icon} />}
            />
          ))}
        </div>

        <div className={styles.setlists}>
          {!isCollapsed && isPlaylistEnabled && <PlaylistsSidebar />}

          {isCollapsed && (
            <div className={styles.setlistCollapsed}>
              <Button
                small={isCollapsed}
                disabled={loading}
                onClick={onGoLibrary}
                className={styles.button}
                icon={<Icon width={20} height={20} name="music-list" />}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={classnames(styles.user, {
          [styles.collapsed]: isCollapsed,
          [styles.extraHeight]:
            !isCollapsed && bannerCountdown.showBannerCountdown
        })}
      >
        {loading ? (
          <Loading className={styles.loading} type="skeleton-profile" />
        ) : error ? (
          <NavUserError
            small={isCollapsed}
            className={styles.loading}
            title={i18n._(t`task.list.state.failed`)}
          />
        ) : (
          <NavUser small={isCollapsed} bannerCountdown={bannerCountdown} />
        )}
      </div>
    </div>
  )
}
