import { DropMenu, Icon } from 'scala'
import classnames from 'classnames'
import React, { RefObject, useCallback, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import styles from './actions-playlist.module.scss'

interface ActionsPlaylistProps {
  playlistId: string | null
  events?: {
    [key: string]: any
  }
  right?: boolean
  small?: boolean
  className?: string
  DeletePlaylist?: React.ReactNode
  EditPlaylist?: React.ReactNode
  UnsubscribePlaylist?: React.ReactNode
}

export const ActionsPlaylist: React.FC<ActionsPlaylistProps> = ({
  className,
  right,
  small,
  playlistId,
  events,
  DeletePlaylist,
  EditPlaylist,
  UnsubscribePlaylist
}) => {
  const ref: RefObject<any> = useRef()
  const [showDrop, setShowDrop] = useState(false)
  useOnClickOutside(ref, () => setShowDrop(false))

  const handleClickShowOptions = useCallback(
    (event: any) => {
      event.stopPropagation()
      setShowDrop(!showDrop)
    },
    [showDrop]
  )

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={classnames(styles.drop, className, {
          [styles.show]: showDrop
        })}
      >
        <button
          type="button"
          className={classnames(styles.button, {
            [styles.small]: small
          })}
          onClick={handleClickShowOptions}
        >
          <Icon
            name="more"
            width={small ? 16 : 24}
            height={small ? 16 : 24}
            className={styles.iconMore}
          />
        </button>

        <DropMenu
          className={classnames(styles.dropMenu, {
            [styles.right]: right,
            [styles.show]: showDrop
          })}
        >
          {EditPlaylist &&
            React.isValidElement(EditPlaylist) &&
            React.cloneElement(EditPlaylist as React.ReactElement<any>, {
              playlistId,
              events
            })}

          {DeletePlaylist &&
            React.isValidElement(DeletePlaylist) &&
            React.cloneElement(DeletePlaylist as React.ReactElement<any>, {
              playlistId,
              events
            })}

          {UnsubscribePlaylist &&
            React.isValidElement(UnsubscribePlaylist) &&
            React.cloneElement(UnsubscribePlaylist as React.ReactElement<any>, {
              playlistId,
              events
            })}
        </DropMenu>
      </div>
    </div>
  )
}
