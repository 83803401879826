import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classNames from 'classnames'
import React from 'react'
import styles from './define-default-separation.module.scss'

interface DefineDefaultSeparationProps {
  active: boolean
  onToggleSetDefaultSeparation(): void
}

export const DefineDefaultSeparation: React.FC<
  DefineDefaultSeparationProps
> = ({ active, onToggleSetDefaultSeparation }) => {
  const { i18n } = useLingui()

  return (
    <button
      id="default_separation_checkbox"
      type="button"
      className={styles.container}
      onClick={onToggleSetDefaultSeparation}
    >
      <div
        className={classNames(styles.checkbox, {
          [styles.active]: active
        })}
      >
        {active && <Icon width={14} height={14} name="check-simple" />}
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{i18n._(t`set_selected_option`)}</p>
        <p className={styles.description}>
          {i18n._(t`change_selected_option`)}
        </p>
      </div>
    </button>
  )
}
