import { ChangeEvent, useCallback, useState } from 'react'
import { Playlist } from '../../../types'

interface UseFilterPlaylists {
  playlists: Playlist[]
  searchTerm: string
  onClear(): void
  onChange(event: ChangeEvent<HTMLInputElement>): void
}

interface UseFilterProps {
  playlists: Playlist[] | null
}

export const useFilterPlaylists = ({
  playlists
}: UseFilterProps): UseFilterPlaylists => {
  const [searchTerm, setSearchTerm] = useState('')

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchTerm(event.currentTarget.value),
    []
  )

  const onClear = useCallback(() => setSearchTerm(''), [])

  const filterByText = useCallback(
    (playlist: Playlist): boolean => {
      if (!searchTerm) return true

      return playlist.name
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase())
    },
    [searchTerm]
  )

  return {
    playlists: playlists?.filter(filterByText) || [],
    searchTerm,
    onChange,
    onClear
  }
}
