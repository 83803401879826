import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import { Step } from '../../../../types'
import styles from './carousel-steps-header.module.scss'
import { TitleBar } from '../../../task-library/title-bar'

export interface StepsHeaderProps {
  beta?: string
  title?: string
  description?: string
  tooltip?: {
    title: string
    description: string
  }
  className?: string
  steps: Step[]
  activeStepIndex: number
}

export const CarouselStepsHeader: React.FC<StepsHeaderProps> = ({
  title,
  description,
  className,
  steps,
  activeStepIndex
}) => (
  <header className={classnames(className, styles.header)}>
    <div className={styles.bar}>
      <TitleBar title={title} subtitle={description} />
    </div>

    <ul className={styles.nav}>
      {steps?.map((step: Step, index: number) => (
        <li
          key={step.title}
          className={classnames(styles.navItem, {
            [styles.active]: activeStepIndex === index,
            [styles.check]: activeStepIndex > index
          })}
        >
          <Icon
            name="check"
            width={16}
            height={16}
            className={classnames(styles.icon, {
              [styles.show]: activeStepIndex > index
            })}
          />
          <p
            className={classnames(styles.index, {
              [styles.active]: activeStepIndex === index,
              [styles.check]: activeStepIndex > index
            })}
          >
            {index + 1}
          </p>

          <p
            className={classnames(styles.titleStep, {
              [styles.active]: activeStepIndex === index,
              [styles.check]: activeStepIndex > index
            })}
          >
            {step?.title}
          </p>
        </li>
      ))}
    </ul>

    <span className={styles.emptySpan} />
  </header>
)
