import classnames from 'classnames'
import React from 'react'
import styles from './promo-details.module.scss'

interface PromoDetailsProps {
  className?: string
  title?: string
  description?: string
  labelDiscount?: number | string
}

export const PromoDetails: React.FC<PromoDetailsProps> = ({
  className,
  title,
  description,
  labelDiscount
}) => (
  <div className={classnames(className, styles.container)}>
    {title && <p className={styles.title}>{title}</p>}

    {description && <p className={styles.description}>{description}</p>}

    {labelDiscount ? (
      <p className={styles.labelDiscount}>{labelDiscount}</p>
    ) : null}

    <svg
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <path
        d="M0 11L28 11C23.0971 11 18.6149 8.2299 16.4223 3.84458L15.3176 1.63522C14.7747 0.549451 13.2253 0.549449 12.6824 1.63522L11.5777 3.84458C9.38505 8.22989 4.90293 11 0 11Z"
        fill="#63fb97"
      />
    </svg>
  </div>
)
