import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import styles from './empty-search.module.scss'

interface EmptySearchProps {
  searchTermOnTracks?: string
}

export const EmptySearch: React.FC<EmptySearchProps> = ({
  searchTermOnTracks
}) => {
  const { i18n } = useLingui()

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {i18n._(t`add.playlist.empty`)} <br />
        <strong>“{searchTermOnTracks}”</strong>
      </p>
    </div>
  )
}
