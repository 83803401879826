import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useEffect, useState } from 'react'
import { config } from '../../config'
import { Context } from '../../context'
import { Moises as MoisesNew } from '../../lib/cli'

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

interface UseComposerParams {
  isAuth: boolean
  userId?: string
}

export const useAuthJwt = (): UseComposerParams => {
  const { user } = useContext(ContextAppFrame)
  const [isAuth, setAuth] = useState(false)
  const { userToken } = useContext(Context)

  const signTempComposerJwt = useCallback(async () => {
    if (!isAuth && userToken) {
      MoisesCLI.auth(userToken)
      await MoisesCLI.signTempComposerJwt()
      setAuth(true)
    }
  }, [isAuth, userToken])

  useEffect(() => {
    signTempComposerJwt()
  }, [signTempComposerJwt])

  return {
    isAuth,
    userId: user?.id
  }
}
