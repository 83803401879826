import { useCallback, useState } from 'react'

export interface UseFailedPaymentAttempt {
  increaseStripeErrorCount(): void
  stripe: number
}

export const useFailedPaymentAttempt = (): UseFailedPaymentAttempt => {
  const STRIPE_KEY = 'moises:stripe:failed:payment:count'
  const [, refresh] = useState(Date.now())

  const getValue = useCallback((): number => {
    if (!global.window || !global.window.localStorage) return 0

    return global.window.localStorage.getItem(STRIPE_KEY)
      ? parseInt(global.window.localStorage.getItem(STRIPE_KEY) as string, 10)
      : 0
  }, [])

  const storedStripeFailCount = getValue()

  const increaseStripeErrorCount = useCallback((): void => {
    global.window.localStorage.setItem(STRIPE_KEY, `${getValue() + 1}`)
    refresh(Date.now())
  }, [getValue])

  return {
    increaseStripeErrorCount,
    stripe: storedStripeFailCount
  }
}
