import React, { useMemo } from 'react'
import ReactModal from 'react-modal'
import { Button } from '../button'
import { Icon } from '../icon'
import styles from './modal.module.scss'

export interface ModalProps {
  isOpen: boolean
  label: string
  targetId: string
  modalClassName?: string
  children?: React.ReactNode
  showCloseButton?: boolean
  shouldCloseOnOverlayClick?: boolean
  onClose?(e?: any): void
}

export const Modal: React.FC<ModalProps> = ({
  label,
  targetId,
  isOpen,
  onClose,
  children,
  modalClassName,
  showCloseButton,
  shouldCloseOnOverlayClick = false
}) => {
  const appElement = useMemo(() => {
    if (typeof window === 'object') {
      return document?.getElementById(targetId)
    }

    return null
  }, [targetId])

  if (!appElement) return null

  return (
    <ReactModal
      isOpen={isOpen}
      preventScroll
      appElement={appElement}
      closeTimeoutMS={200}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={styles.overlay}
      className={styles.modal}
      contentLabel={label}
    >
      <div className={modalClassName}>
        <div className={styles.container}>
          {showCloseButton && (
            <Button
              transparent
              id="modal_button_close"
              className={styles.closeButton}
              icon={<Icon name="close" className={styles.closeIcon} />}
              onClick={onClose}
            />
          )}
          <div>{children}</div>
        </div>
      </div>
    </ReactModal>
  )
}
