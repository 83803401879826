import { propEq } from 'ramda'
import { useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { Playlist } from '../../../types'
import { useFetchPlaylists } from '../use-fetch-playlists'

function byDateAsc(a: Playlist, b: Playlist): number {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
}

export function useMoisesCollection(): Playlist | null {
  const { playlists: guestPlaylists, fetchPlaylists } = useFetchPlaylists({
    created: false
  })

  useEffectOnce(() => {
    fetchPlaylists()
  })

  return useMemo(() => {
    const globals = guestPlaylists
      ?.filter(propEq('isGlobal', true))
      ?.sort(byDateAsc)
    return globals?.[0] || null
  }, [guestPlaylists])
}
