import React from 'react'
import classnames from 'classnames'

import { DropMenuProps } from '../../types'

import styles from './drop-menu.module.scss'

export const DropMenu: React.FC<DropMenuProps> = ({ className, children }) => (
  <div className={classnames(className, styles.dropMenu)}>{children}</div>
)
