import classnames from 'classnames'
import React from 'react'
import styles from './advanced-options.module.scss'
import { AdvancedOptionsProps } from './types'

export const AdvancedOptions: React.FC<AdvancedOptionsProps> = ({
  className,
  children
}) => (
  <div className={classnames(className, styles.container)}>
    {children || null}
  </div>
)
