import { Button, Icon, Modal } from 'scala'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import styles from './modal-confirmation-column.module.scss'

export interface ModalConfirmation {
  isOpen: boolean
  loading: boolean
  title: string
  info?: string
  flexColumn?: boolean
  confirmLabel: string
  cancelLabel: string
  loadingLabel: string
  showCloseButton?: boolean
  onConfirm(): void
  onDismiss(): void
}

export const ModalConfirmationColumn: React.FC<ModalConfirmation> = ({
  isOpen,
  loading,
  title,
  info,
  loadingLabel,
  confirmLabel,
  cancelLabel,
  showCloseButton = true,
  onConfirm,
  onDismiss
}) => {
  const handleDismiss = useCallback(() => {
    if (!loading) onDismiss()
  }, [loading, onDismiss])

  return (
    <Modal
      targetId="moises-app"
      label={title}
      isOpen={isOpen}
      onClose={handleDismiss}
      modalClassName={styles.modal}
      showCloseButton={showCloseButton}
      shouldCloseOnOverlayClick={!loading}
    >
      <div
        className={classnames(styles.content, {
          [styles.hideClose]: !showCloseButton
        })}
      >
        <p className={styles.heading}>{title}</p>

        {info && <p className={styles.info}>{info}</p>}

        <div className={styles.actions}>
          <Button
            small
            light
            radius={1}
            disabled={loading}
            title={cancelLabel}
            onClick={handleDismiss}
            className={styles.button}
          />
          <Button
            small
            radius={1}
            transparent
            disabled={loading}
            onClick={onConfirm}
            className={classnames(styles.cancel, styles.button)}
            title={loading ? loadingLabel : confirmLabel}
            icon={loading ? <Icon name="loader" animation="spin" /> : undefined}
          />
        </div>
      </div>
    </Modal>
  )
}
