import { useCallback, useState } from 'react'
import { TaskConfig } from './types'

export const useTaskConfig = (): {
  splitConfig: TaskConfig | null
  onSetSplitConfig(config: TaskConfig): void
} => {
  const [splitConfig, setsplitConfig] = useState<TaskConfig | null>(null)
  const onSetSplitConfig = useCallback(
    (config: any) => setsplitConfig(config),
    []
  )

  return {
    splitConfig,
    onSetSplitConfig
  }
}
