import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Icon } from 'scala'
import React from 'react'
import { SeparationOption } from '../../../../hooks/use-default-separation'
import { InfoDefaultSeparation } from '../info-default-separation'
import { TrackDetail } from '../track-detail'
import styles from './carousel-steps-footer.module.scss'

export interface StepsFooterProps {
  labelNext?: string
  labelPrevious?: string
  canMoveBackward: boolean
  moveBackward(): void
  moveForward(): void
  onShowSeparationType?(): void
  canMoveForward: boolean
  moveForwardDisabled: boolean
  footerInfo?: {
    filename?: string | null
    filenameReference?: string | null
  }
  defaultSeparationSelected?: SeparationOption
  defaultSeparationEnabled?: boolean
  showStepPreferences?: boolean
  isLastStep?: boolean
}

export const CarouselStepsFooter: React.FC<StepsFooterProps> = ({
  labelNext,
  labelPrevious,
  footerInfo,
  canMoveBackward,
  moveBackward,
  moveForward,
  canMoveForward,
  moveForwardDisabled,
  showStepPreferences,
  defaultSeparationSelected,
  defaultSeparationEnabled,
  onShowSeparationType,
  isLastStep
}) => {
  const { i18n } = useLingui()

  return (
    <footer className={styles.footer}>
      <div className={styles.tracks}>
        {footerInfo && footerInfo.filename && (
          <TrackDetail
            title={i18n._(t`upload.footer.track`)}
            filename={footerInfo.filename}
          />
        )}

        {footerInfo && footerInfo.filenameReference && (
          <TrackDetail
            title={i18n._(t`upload.footer.reference`)}
            filename={footerInfo.filenameReference}
          />
        )}
      </div>

      {defaultSeparationEnabled &&
        !showStepPreferences &&
        defaultSeparationSelected && (
          <InfoDefaultSeparation
            {...defaultSeparationSelected}
            moveForward={moveForward}
            moveForwardDisabled={moveForwardDisabled}
            onShowSeparationType={onShowSeparationType}
          />
        )}

      <div className={styles.actions}>
        <span>
          {canMoveBackward && (
            <Button
              id="upload_previous_button"
              small
              transparent
              title={labelPrevious}
              onClick={moveBackward}
              disabled={!canMoveBackward}
              className={styles.button}
              icon={<Icon name="arrow" className={styles.iconPrevious} />}
            />
          )}
        </span>

        {canMoveForward && (
          <Button
            id={isLastStep ? 'upload_submit_button' : 'upload_next_button'}
            small
            radius={1}
            title={labelNext}
            onClick={moveForward}
            disabled={moveForwardDisabled}
            className={styles.button}
            icon={<Icon name="arrow" className={styles.iconNext} />}
          />
        )}
      </div>
    </footer>
  )
}
