import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Collapse } from '../../..'
import { Checkbox, Select } from '../../../form'
import { AdvancedOptions } from '../../common/advanced-options'
import styles from './config-master.module.scss'
import { ConfigMasterProps } from './config-master.types'

export const ConfigMaster: React.FC<ConfigMasterProps> = ({
  onSetMasterConfig
}) => {
  const { i18n } = useLingui()
  const [limiter, setLimiter] = useState(true)
  const [normalize] = useState(false)
  const [targetLufs, setTargetLufs] = useState('-14')
  const [bit, setBit] = useState('24')

  const handleSetLimiter = useCallback(
    () => setLimiter(!limiter),
    [setLimiter, limiter]
  )

  const handleSetLufs = useCallback(
    (value: string) => setTargetLufs(value),
    [setTargetLufs]
  )

  const handleSetBit = useCallback((value: string) => setBit(value), [setBit])

  useEffect(() => {
    onSetMasterConfig({
      limiter,
      normalize,
      bit: parseInt(bit, 10),
      targetLufs: parseInt(targetLufs || '0', 10)
    })
  }, [limiter, normalize, targetLufs, bit, onSetMasterConfig])

  const lufsOptions = [
    { value: '', text: i18n._(t`label.custom`) },
    {
      value: '-11',
      text: `${i18n._(t`label.spotify`)}, ${i18n._(t`label.amazon.music.loud`)}`
    },
    {
      value: '-14',
      text: `${i18n._(t`label.youtube`)}, ${i18n._(
        t`label.soundcloud`
      )}, ${i18n._(t`label.tidal`)}`
    },
    {
      value: '-16',
      text: `${i18n._(t`label.apple.music`)}, ${i18n._(
        t`label.apple.podcasts`
      )}, ${i18n._(t`label.aes`)}`
    },
    { value: '-13', text: i18n._(t`label.amazon.music.normal`) },
    { value: '-15', text: i18n._(t`label.deezer`) },
    { value: '-6', text: i18n._(t`label.club.play.loud`) },
    {
      value: '-9',
      text: `${i18n._(t`label.club.play.normal`)}, ${i18n._(t`label.cd`)}`
    }
  ]

  const bitOptions = [
    {
      text: `16 ${i18n._(t`upload.options.bit.label`)}`,
      value: '16'
    },
    {
      text: `24 ${i18n._(t`upload.options.bit.label`)}`,
      value: '24'
    },
    {
      text: `32 ${i18n._(t`upload.options.bit.label`)}`,
      value: '32'
    }
  ]

  return (
    <div className={styles.container}>
      <Collapse title={i18n._(t`upload.separate.steps.settings`)}>
        <AdvancedOptions>
          <Checkbox
            title={i18n._(t`upload.options.limiter`)}
            active={limiter}
            onChange={handleSetLimiter}
          />

          {limiter && (
            <Select
              customValue
              title={i18n._(t`upload.options.lufs.option`)}
              defaultValue={targetLufs}
              options={lufsOptions}
              onChange={handleSetLufs}
            />
          )}

          <Select
            title={i18n._(t`upload.options.bit.option`)}
            defaultValue={bit}
            options={bitOptions}
            onChange={handleSetBit}
          />
        </AdvancedOptions>
      </Collapse>
    </div>
  )
}
