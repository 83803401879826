import { useCallback, useState } from 'react'

export function useToggle(initialValue?: boolean): {
  value: boolean
  toggle: () => void
  toggleTrue: () => void
  toggleFalse: () => void
} {
  const [value, setValue] = useState(initialValue || false)
  const toggle = useCallback(() => setValue((prevValue) => !prevValue), [])
  const toggleTrue = useCallback(() => setValue(true), [])
  const toggleFalse = useCallback(() => setValue(false), [])
  return { value, toggle, toggleTrue, toggleFalse }
}
