import { useCallback, useState } from 'react'
import { TaskConfig } from './types'

export const useTaskConfig = (): {
  masterConfig: TaskConfig
  onSetMasterConfig(config: TaskConfig): void
} => {
  const [masterConfig, setMasterConfig] = useState<TaskConfig>({
    bit: 24,
    limiter: true,
    normalize: false,
    targetLufs: -14
  })

  const onSetMasterConfig = useCallback(
    ({ bit, limiter, normalize, targetLufs }: any) => {
      setMasterConfig({
        bit,
        limiter,
        normalize,
        targetLufs
      })
    },
    []
  )

  return {
    masterConfig,
    onSetMasterConfig
  }
}
