import React, { useCallback, useContext, useState, useEffect } from 'react'
import classnames from 'classnames'
import { Context } from '../context'
import { Icon } from '../icon'
import { NavProps } from '../../types'

import styles from './nav.module.scss'

type NavItem = {
  title: string
  id?: string
  active: boolean
  onClick(): void
}

export const Nav: React.FC<NavProps> = ({ className, items }) => {
  const { onNavigate } = useContext(Context)

  const [itemsNav, setItemsNav] = useState<NavItem[]>([])
  const [openedModal, setOpenedModal] = useState(false)

  const onOpenModal = useCallback(() => setOpenedModal(true), [])

  const onCloseModal = useCallback(() => setOpenedModal(false), [])

  const onClickItem = useCallback(
    (link: string) => {
      onCloseModal()
      onNavigate(link)
    },
    [onNavigate, onCloseModal]
  )
  useEffect(() => {
    const list = items.map((item) => ({
      title: item.label,
      id: item.id,
      active: !!item.active,
      onClick: () => onClickItem(item.link)
    }))

    setItemsNav(list)
  }, [items, onClickItem])

  return (
    <nav className={classnames(className, styles.nav)}>
      <button type="button" onClick={onOpenModal} className={styles.buttonMenu}>
        <Icon name="menu" />
      </button>

      <ul className={styles.navDesktop}>
        {itemsNav.map((item) => (
          <li key={item.title} className={styles.navItem}>
            <button
              id={item.id}
              type="button"
              onClick={item.onClick}
              className={classnames(styles.navLink, {
                [styles.active]: item.active
              })}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>

      {!openedModal ? null : (
        <>
          <button
            type="button"
            onClick={onCloseModal}
            className={styles.buttonClose}
          >
            <Icon name="close" />
          </button>

          <ul className={styles.navMobile}>
            {itemsNav.map((item) => (
              <li key={item.title} className={styles.navItem}>
                <button
                  id={item.id && `${item.id}_mobile`}
                  type="button"
                  onClick={item.onClick}
                  className={classnames(styles.navLink, {
                    [styles.active]: item.active
                  })}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </nav>
  )
}
