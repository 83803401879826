import React from 'react'
import classnames from 'classnames'
import { ButtonIconProps } from '../../types'
import styles from './button-icon.module.scss'
import { Tooltip } from '../tooltip'

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  id,
  beta,
  title,
  light,
  oneLine,
  tabindex = 0,
  active = false,
  disabled = false,
  className,
  onClick,
  iconPosition = 'left',
  tooltip
}) => (
  <button
    id={id}
    type="button"
    tabIndex={tabindex}
    disabled={disabled}
    onClick={(e: any) => (onClick ? onClick(e) : null)}
    className={classnames(className, styles.button, {
      [styles.light]: light,
      [styles.active]: active,
      [styles.disabled]: disabled
    })}
  >
    {icon && iconPosition === 'left' && (
      <span className={styles.icon}>{icon}</span>
    )}
    <p
      className={classnames(styles.label, {
        [styles.oneLine]: oneLine
      })}
    >
      {title} {beta && <span className={styles.beta}>{beta}</span>}
    </p>
    {icon && iconPosition === 'right' && (
      <>
        <div className={styles.container}>
          <span className={`${styles.icon} ${styles.iconTooltip}`}>{icon}</span>
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              nozzle
              topRight
            />
          )}
        </div>
      </>
    )}
  </button>
)
