import React, { useEffect, useMemo, useState } from 'react'
import { useEffectOnce, useWindowSize } from 'react-use'
import { useGetUserData } from './hooks/use-get-user-data'
import { ContextProvider } from '../context'
import { Error } from '../error'
import { AppFrameProps } from '../../types'
import styles from './app-frame.module.scss'
import { Content } from './content'
import { HeaderBar } from '../header-bar'

export const AppFrame: React.FC<AppFrameProps> = ({
  apiUrl,
  strings = {},
  auth,
  children,
  Sidebar,
  AlertGetApp,
  onNavigate,
  onUserLoaded,
  clientHeaders,
  currentPathName,
  navItems = []
}) => {
  const { loading, value, error, retry, silentRetry } = useGetUserData({
    apiUrl,
    clientHeaders,
    auth
  })
  const hasError = !!error

  const { width } = useWindowSize()
  const isMobileViewport = useMemo(() => width < 639, [width])
  const [canShowHeader, setCanShowHeader] = useState(false)
  useEffectOnce(() => setCanShowHeader(true))

  useEffect(() => {
    if (onUserLoaded && value?.user) onUserLoaded(value.user)
  }, [onUserLoaded, value])

  return (
    <ContextProvider
      silentRetry={silentRetry}
      user={value?.user}
      loadingUserInfo={loading}
      onNavigate={onNavigate}
      currentPathName={currentPathName}
    >
      {isMobileViewport && canShowHeader && (
        <HeaderBar
          strings={strings}
          items={navItems}
          error={hasError}
          loading={loading}
          AlertGetApp={AlertGetApp}
        />
      )}

      {!loading && hasError && (
        <Error
          className={styles.error}
          title={strings['page.errorTitle'] || 'Oops...'}
          message={
            strings['page.errorLabel'] ||
            "Sorry, we couldn't load your information."
          }
          textRetry={strings['page.btnRetry'] || 'Try again'}
          retry={retry}
        />
      )}
      {!hasError && (
        <Content Sidebar={Sidebar} loading={loading} error={hasError}>
          {children}
        </Content>
      )}
    </ContextProvider>
  )
}
