import { useEffect } from 'react'
import { useCreateTask } from '../use-create-task'
import { TaskFile, TaskStep } from '../use-create-task/common'

interface UseCreateTaskDenoiserParams {
  taskFile: TaskFile
  activeStepIndex: TaskStep
}

interface UseCreateTaskDenoiser {
  loadingCreateTask: boolean
  errorCreateTask: Error | undefined
  taskCreatedId: string | undefined
}

export const useSubmitDenoiserTask = ({
  taskFile,
  activeStepIndex
}: UseCreateTaskDenoiserParams): UseCreateTaskDenoiser => {
  const {
    loading: loadingCreateTask,
    error: errorCreateTask,
    value: taskCreatedId,
    createTask
  } = useCreateTask()

  useEffect(() => {
    // automatically submit the task when all required conditions are met
    if (!taskFile?.tempLocation || activeStepIndex !== 1) return

    createTask({
      file: taskFile,
      operations: [
        {
          id: 'DENOISER_A',
          params: {}
        }
      ]
    })
  }, [activeStepIndex, createTask, taskFile])

  const requiredConditions = taskFile?.tempLocation && activeStepIndex === 1

  return {
    loadingCreateTask,
    errorCreateTask:
      errorCreateTask && requiredConditions ? errorCreateTask : undefined,
    taskCreatedId
  }
}
