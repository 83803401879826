import { useEffect, RefObject } from 'react'

type AnyEvent = MouseEvent | TouchEvent

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent | null) => void
): any => {
  useEffect(() => {
    const samePageListener = (event: AnyEvent): any => {
      const el = ref?.current

      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    // Clicks inside the iframe doesn't trigger mousedown and
    //  touchstart events on the document
    const iframeListener = (): any => {
      // setTimeout is an workaround for Firefox
      // https://gist.github.com/jaydson/1780598?
      // permalink_comment_id=2609301#gistcomment-2609301
      setTimeout(() => {
        if (document.activeElement instanceof HTMLIFrameElement) {
          handler(null)
        }
      }, 0)
    }

    document.addEventListener('mousedown', samePageListener)
    document.addEventListener('touchstart', samePageListener)
    window.addEventListener('blur', iframeListener)

    return () => {
      document.removeEventListener('mousedown', samePageListener)
      document.removeEventListener('touchstart', samePageListener)
      window.removeEventListener('blur', iframeListener)
    }
  }, [ref, handler])
}
