import { useAsyncFn } from 'react-use'
import { moisesSDK } from '../../../lib/firebase'

interface UseDeleteTask {
  taskDeleted: boolean
  error?: Error
  loading: boolean
  deleteTask(taskId: string): any
}

export const useDeleteTask = (): UseDeleteTask => {
  const [state, deleteTask] = useAsyncFn(async (taskId: string) => {
    const query = `
    mutation DeleteTask($id: String!) {
      deleteTask(id: $id)
    }`

    const { deleteTask: taskDeleted } = await moisesSDK.graphQL.fetch({
      query,
      variables: {
        id: taskId
      }
    })

    return taskDeleted
  }, [])

  const { loading, error, value } = state

  return {
    loading,
    error,
    taskDeleted: value === true,
    deleteTask
  }
}
