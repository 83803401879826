import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Icon } from 'scala'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'
import styles from './pricing-details.module.scss'

interface PricingDetailsProps {
  className?: string
  active?: boolean
  isPromo?: boolean
  title: string
  headline?: string
  description?: string
  price: string
  regularPrice?: string
  link: string
  priceDetail?: string
  labelDiscount?: string | number
}

export const PricingDetails: React.FC<PricingDetailsProps> = ({
  className,
  isPromo,
  active,
  title,
  headline,
  description,
  price,
  regularPrice,
  link,
  priceDetail,
  labelDiscount
}) => {
  const { i18n } = useLingui()

  return (
    <Link href={link} legacyBehavior>
      <a
        href={link}
        className={classnames(className, styles.container, {
          [styles.active]: active
        })}
      >
        {labelDiscount && (
          <p
            className={classnames(styles.labelDiscount, {
              [styles.big]: isPromo
            })}
          >
            {labelDiscount}
          </p>
        )}

        {headline && <p className={styles.headline}>{headline}</p>}

        <div
          className={classnames(className, styles.content, {
            [styles.active]: active
          })}
        >
          <h3 className={styles.title}>{title}</h3>

          <p className={styles.price}>
            {price}
            <span className={styles.label}>
              /{i18n._(t`subscribe.plan.monthly.initials`)}
            </span>

            {regularPrice && (
              <span className={styles.regularPrice}>{regularPrice}</span>
            )}
          </p>

          <p className={styles.priceDetail}>{priceDetail}</p>

          <Button
            small
            radius={1}
            className={styles.button}
            icon={<Icon name="arrow" className={styles.icon} />}
            title={i18n._(t`subscribe`)}
          />

          {description && <p className={styles.description}>{description}</p>}
        </div>
      </a>
    </Link>
  )
}
