import { useEffect } from 'react'
import { useCreateTask } from '../use-create-task'
import { TaskFile, TaskStep } from '../use-create-task/common'
import { CreateTaskOperation } from '../use-create-task/types'
import { TaskConfig } from './types'

interface UseCreateTaskSplitParams {
  taskFile: TaskFile
  splitConfig: TaskConfig | null
  activeStepIndex: TaskStep
  lastStepIndex: number
}

interface UseCreateTaskSplit {
  loadingCreateTask: boolean
  errorCreateTask: Error | undefined
  taskCreatedId: string | undefined
}

export const useSubmitSplitTask = ({
  taskFile,
  splitConfig,
  activeStepIndex,
  lastStepIndex
}: UseCreateTaskSplitParams): UseCreateTaskSplit => {
  const {
    loading: loadingCreateTask,
    error: errorCreateTask,
    value: taskCreatedId,
    createTask
  } = useCreateTask()

  useEffect(() => {
    // automatically submit the task when all required conditions are met
    if (
      !splitConfig ||
      !taskFile?.tempLocation ||
      activeStepIndex !== lastStepIndex
    ) {
      return
    }

    const operations = [
      splitConfig,
      {
        id: 'BEATSCHORDS_A',
        params: {}
      }
    ]

    const task = {
      operations,
      file: taskFile
    } as CreateTaskOperation

    createTask(task)
  }, [activeStepIndex, lastStepIndex, createTask, taskFile, splitConfig])

  const requiredConditions =
    taskFile?.tempLocation &&
    splitConfig?.params.type &&
    activeStepIndex === lastStepIndex

  return {
    loadingCreateTask,
    errorCreateTask:
      errorCreateTask && requiredConditions ? errorCreateTask : undefined,
    taskCreatedId
  }
}
