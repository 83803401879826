import axios from 'axios'
import { pickAll } from 'ramda'
import { config } from '../../../config'
import { getCookies } from '../../../lib/cookies'

interface ConversionParam {
  name: string
  userToken: string
  fbEventId: string
  customAttributes?: {
    [key: string]: string | string[] | number | boolean | undefined
  }
}

export const sendConversionEvent = ({
  name,
  customAttributes,
  fbEventId,
  userToken
}: ConversionParam): void => {
  const cookies = getCookies()

  axios.post(
    `${config.api.endpoint}/v3/track-events`,
    {
      ...pickAll(['_fbp', '_fbc'], cookies),
      source: 'website',
      sourceUrl: global.window.location.href,
      event: {
        id: fbEventId,
        name,
        customAttributes
      }
    },
    {
      headers: {
        Authorization: userToken
      }
    }
  )
}
