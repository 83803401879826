import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Icon } from 'scala'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'
import styles from './pricing-details-small.module.scss'

interface PricingDetailsSmallProps {
  className?: string
  active?: boolean
  title: string
  price: string
  regularPrice?: string
  labelDiscount?: string
  link: string
}

export const PricingDetailsSmall: React.FC<PricingDetailsSmallProps> = ({
  className,
  active,
  title,
  price,
  regularPrice,
  labelDiscount,
  link
}) => {
  const { i18n } = useLingui()

  return (
    <Link href={link} legacyBehavior>
      <a
        href={link}
        className={classnames(className, styles.container, {
          [styles.active]: active
        })}
      >
        <div className={styles.header}>
          <h3 className={styles.title}>
            {title}

            {labelDiscount && (
              <p className={styles.labelDiscount}>{labelDiscount}</p>
            )}
          </h3>

          <p className={styles.price}>
            {price}
            <span className={styles.label}>
              /{i18n._(t`subscribe.plan.monthly.initials`)}
            </span>

            {regularPrice && (
              <span className={styles.regularPrice}>{regularPrice}</span>
            )}
          </p>
        </div>

        <Button
          small
          radius={1}
          className={styles.button}
          icon={<Icon name="arrow" className={styles.icon} />}
          title={i18n._(t`label.getstarted`)}
        />
      </a>
    </Link>
  )
}
