import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import { HeaderSectionUpload } from '../upload/common/header-section-upload'
import styles from './collapse.module.scss'
import { CollapseProps } from './collapse.types'

export const Collapse: React.FC<CollapseProps> = ({
  title,
  className,
  opened = false,
  children
}) => {
  const [show, setShow] = useState(opened)
  const handleClick = useCallback(() => setShow(!show), [setShow, show])

  return (
    <div className={className}>
      <HeaderSectionUpload
        title={title}
        icon={<Icon name="arrow-fill" />}
        active={show}
        onClick={handleClick}
      />

      {children ? (
        <div
          className={classnames(styles.content, {
            [styles.show]: show
          })}
        >
          {children}
        </div>
      ) : null}
    </div>
  )
}
