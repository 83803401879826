import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useTimeoutFn } from 'react-use'
import { useComposer } from '../../composer'

interface UseDelayPublicPage {
  isAuth: boolean
  userId?: string
  featureFlagsString: string
  shouldRender: boolean
  goPageSignIn(): void
  cancelTimeout(): void
}

export const useDelayPublicPage = (): UseDelayPublicPage => {
  const { asPath, push } = useRouter()
  const [shouldRender, setShouldRender] = useState(false)
  const { userId, isAuth, featureFlagsString } = useComposer()
  const path = asPath.split('/').slice(1).join('/')

  const goPageSignIn = useCallback(
    () => push(`/login?redirect=${path}&signup`),
    [push, path]
  )

  const [isReady, cancelTimeout] = useTimeoutFn(() => {
    setShouldRender(true)
  }, 5000)

  useEffect(() => {
    if (isAuth && userId && !isReady()) {
      cancelTimeout()
      setShouldRender(true)
    }
  }, [isAuth, userId, cancelTimeout, isReady])

  return {
    isAuth,
    userId,
    goPageSignIn,
    cancelTimeout,
    shouldRender,
    featureFlagsString
  }
}
