import { ChangeEvent, useCallback, useState } from 'react'
import { useDebounce } from 'react-use'

export interface UseSearchTasks {
  value: string
  filterTrackByText: string
  onClear(): void
  onChange(event: ChangeEvent<HTMLInputElement>, val?: string): void
}

export const useSearchTasks = (): UseSearchTasks => {
  const [value, setValue] = useState('')
  const [filterTrackByText, setFilterTrackByText] = useState('')

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, val?: string) => {
      setValue(val || event.currentTarget.value)
    },
    []
  )

  useDebounce(
    () => {
      setFilterTrackByText(value.trim())
    },
    500,
    [value]
  )

  const onClear = useCallback(() => setValue(''), [])

  return {
    value,
    filterTrackByText,
    onChange,
    onClear
  }
}
