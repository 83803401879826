import classnames from 'classnames'
import React, { useCallback } from 'react'
import { Icon } from '../../icon'
import styles from './form-search.module.scss'

export interface SearchProps {
  placeholder: string
  attributes?: any
  className?: string
  text?: string
  onChange?: (value: string) => void
}

export const FormSearch: React.FC<SearchProps> = ({
  placeholder,
  className,
  text = '',
  attributes = {},
  onChange
}) => {
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) =>
      onChange ? onChange(e.currentTarget.value) : null,
    [onChange]
  )

  return (
    <div className={classnames(className, styles.search)}>
      <Icon name="search" className={styles.icon} />
      <input
        {...attributes}
        type="text"
        className={styles.input}
        placeholder={placeholder}
        onChange={handleChange}
        value={text}
      />
    </div>
  )
}
