import React from 'react'
import classnames from 'classnames'
import { Button } from '../button'

import { ErrorProps } from '../../types'

import styles from './error.module.scss'

export const Error: React.FC<ErrorProps> = ({
  textRetry,
  className,
  retry,
  title,
  message
}) => (
  <div className={classnames(className, styles.errorTemplate)}>
    <p className={styles.title}>{title}</p>
    <p className={styles.message}>{message}</p>
    {retry ? (
      <Button className={styles.btnRetry} title={textRetry} onClick={retry} />
    ) : null}
  </div>
)
