import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

interface UseCountry {
  loading: boolean
  error: Error | null
  countryCode: string | undefined | null
}

const useCountry = (): UseCountry => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [countryCode, setCountryCode] = useLocalStorage(
    'moises:user:country',
    null
  )

  const getCountry = useCallback(async () => {
    if (countryCode !== null) return

    try {
      setLoading(true)

      const locationResponse = await fetch(
        'https://pro.ip-api.com/json/?key=j3nZJdQaEEljdQi&fields=status,countryCode,currency'
      )
      const locationResult = await locationResponse.json()

      if (locationResponse.ok === true && locationResult.status === 'success') {
        const { countryCode: code } = locationResult

        setCountryCode(code)
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, countryCode, setCountryCode, setError])

  useEffect(() => {
    getCountry()
  }, [getCountry])

  return {
    loading,
    error,
    countryCode
  }
}

export default useCountry
