import React, { useState, useEffect, ReactElement } from 'react'

import { ContextProviderProps, UserContext, GenericObject } from '../../types'

export const Context = React.createContext<Partial<GenericObject>>({})

export const ContextConsumer = Context.Consumer

export const ContextProvider = ({
  user,
  loadingUserInfo,
  silentRetry,
  onNavigate,
  currentPathName,
  children
}: ContextProviderProps): ReactElement => {
  const [userState, setUser] = useState<UserContext | null>(user || null)

  useEffect(() => setUser(user), [user])

  return (
    <Context.Provider
      value={{
        user: userState,
        loadingUserInfo,
        currentPathName,
        onNavigate,
        silentRetry
      }}
    >
      {children}
    </Context.Provider>
  )
}
