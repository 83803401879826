import { useCallback } from 'react'
import { FileURL, LocalFile } from './types'

interface UseTaskInputChangeParams {
  abortUploads(): void
  setLocalFile(file: LocalFile): void
  setUrlExternal(url: FileURL): void
}

interface UseTaskInputChange {
  onSetExternalUrl(url: FileURL): void
  onSelectLocalFile(file: LocalFile): void
  resetAllUploads(): void
}

export const useTaskFileChange = ({
  abortUploads,
  setLocalFile,
  setUrlExternal
}: UseTaskInputChangeParams): UseTaskInputChange => {
  const resetAllUploads = useCallback(() => {
    abortUploads()
    setLocalFile(null)
    setUrlExternal(null)
  }, [abortUploads, setLocalFile, setUrlExternal])

  const onSetExternalUrl = useCallback(
    async (url: FileURL) => {
      abortUploads()
      setLocalFile(null)
      setUrlExternal(url)
    },
    [setLocalFile, setUrlExternal, abortUploads]
  )

  const onSelectLocalFile = useCallback(
    async (file: LocalFile) => {
      abortUploads()
      setUrlExternal(null)
      setLocalFile(file)
    },
    [setLocalFile, setUrlExternal, abortUploads]
  )

  return {
    resetAllUploads,
    onSetExternalUrl,
    onSelectLocalFile
  }
}
