import { useCallback } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { moisesSDK } from '../../../lib/firebase'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useQuery({ query, variables, swrConfig = {} }: any): any {
  const request = JSON.stringify({ query, variables })

  const { data, error, mutate, isValidating } = useSWR(
    request,
    (req: any) => moisesSDK.graphQL.fetch(JSON.parse(req)),
    swrConfig
  )

  const { cache } = useSWRConfig()

  const purge = useCallback(() => cache.delete(request), [request, cache])

  return {
    data,
    error,
    mutate,
    purge,
    loading: isValidating
  }
}
