module.exports = {
  v: '5.7.4',
  fr: 29.9700012207031,
  ip: 0,
  op: 70.0000028511585,
  w: 170,
  h: 170,
  nm: 'Spinner',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'spinner Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [-90]
            },
            { t: 70.0000028511585, s: [270] }
          ],
          ix: 10
        },
        p: { a: 0, k: [85, 85, 0], ix: 2, l: 2 },
        a: { a: 0, k: [85.5, 85.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.459],
                    [44.459, 0],
                    [0, 44.459],
                    [-44.459, 0]
                  ],
                  o: [
                    [0, 44.459],
                    [-44.459, 0],
                    [0, -44.459],
                    [44.459, 0]
                  ],
                  v: [
                    [80.5, 0],
                    [0, 80.5],
                    [-80.5, 0],
                    [0, -80.5]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'gs',
              o: { a: 0, k: 100, ix: 9 },
              w: { a: 0, k: 8, ix: 10 },
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0, 0.855, 0.91, 0.5, 0.194, 0.92, 0.751, 1, 0.388, 0.984,
                    0.592
                  ],
                  ix: 8
                }
              },
              s: { a: 0, k: [0, 0], ix: 4 },
              e: { a: 0, k: [100, 0], ix: 5 },
              t: 1,
              lc: 2,
              lj: 1,
              ml: 10,
              ml2: { a: 0, k: 10, ix: 13 },
              bm: 0,
              nm: 'Gradient Stroke 1',
              mn: 'ADBE Vector Graphic - G-Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [85.5, 85.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 20,
                s: [0]
              },
              { t: 70.0000028511585, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 0,
                s: [1]
              },
              { t: 35.0000014255792, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'spinner Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [85, 85, 0], ix: 2, l: 2 },
        a: { a: 0, k: [85.5, 85.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.459],
                    [44.459, 0],
                    [0, 44.459],
                    [-44.459, 0]
                  ],
                  o: [
                    [0, 44.459],
                    [-44.459, 0],
                    [0, -44.459],
                    [44.459, 0]
                  ],
                  v: [
                    [80.5, 0],
                    [0, 80.5],
                    [-80.5, 0],
                    [0, -80.5]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.231372565031, 0.231372565031, 0.231372565031, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [85.5, 85.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}
