import { useCallback, useState } from 'react'
import { LocalFile } from '../use-create-task/common/types'
import { TaskConfig } from './types'

interface UseNavigationParams {
  localFile: LocalFile
  localFileReference: LocalFile
  isExternalUrlValid: boolean
  isExternalUrlReferenceValid: boolean
  masterConfig: TaskConfig
}

interface UseNavigation {
  moveForwardDisabled: boolean
  canMoveBackward: boolean
  canMoveForward: boolean
  activeStepIndex: number
  resetNavigation(): void
  moveForward(): void
  moveBackward(): void
}

export const useTaskNavigation = ({
  localFile,
  localFileReference,
  isExternalUrlValid,
  isExternalUrlReferenceValid,
  masterConfig
}: UseNavigationParams): UseNavigation => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  let moveForwardDisabled = true
  const canMoveBackward = activeStepIndex === 1
  const canMoveForward = activeStepIndex < 2

  const resetNavigation = useCallback(() => {
    setActiveStepIndex(0)
  }, [])

  const moveForward = useCallback(
    () => setActiveStepIndex(activeStepIndex + 1),
    [activeStepIndex]
  )

  const moveBackward = useCallback(() => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1)
    }
  }, [activeStepIndex])

  const hasFileOrUrlInput = isExternalUrlValid || localFile
  const hasFileOrUrlInputReference =
    isExternalUrlReferenceValid || localFileReference
  const hasConfigTypeSet = !!masterConfig

  switch (activeStepIndex) {
    case 0:
      if (hasFileOrUrlInput) moveForwardDisabled = false
      break
    case 1:
      if (hasFileOrUrlInput && hasFileOrUrlInputReference && hasConfigTypeSet) {
        moveForwardDisabled = false
      }
      break
    default:
      moveForwardDisabled = true
  }

  return {
    canMoveForward,
    canMoveBackward,
    moveForwardDisabled,
    activeStepIndex,
    resetNavigation,
    moveForward,
    moveBackward
  }
}
