import { useCallback } from 'react'
import { useRedirectOnTaskCreated } from '..'
import { useFakeProgress } from '../../misc/use-fake-progress'
import {
  useTaskFile,
  useTaskFileChange,
  useTaskUpload
} from '../use-create-task/common'
import { UseCreateDenoiserTask } from './types'
import { useSubmitDenoiserTask } from './use-submit-denoiser-task'
import { useTaskNavigation } from './use-task-navigation'

interface UseCreateDenoiserTaskProps {
  playlistId: null | string
}

export const useCreateDenoiserTask = ({
  playlistId
}: UseCreateDenoiserTaskProps): UseCreateDenoiserTask => {
  const {
    localFile,
    setLocalFile,
    urlExternal,
    setUrlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,
    taskFile,
    setTaskFile
  } = useTaskFile()

  const {
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,
    resetNavigation,
    activeStepIndex
  } = useTaskNavigation({
    localFile
  })

  const {
    // local file
    loadingUploadLocalFile,
    errorUploadLocalFile,
    resultUploadLocalFile,
    abortUploads
  } = useTaskUpload({
    localFile,
    setTaskFile,
    urlExternal,
    isExternalUrlValid: false
  })

  const { loadingCreateTask, errorCreateTask, taskCreatedId } =
    useSubmitDenoiserTask({
      taskFile,
      activeStepIndex
    })

  const { onSelectLocalFile, onSetExternalUrl, resetAllUploads } =
    useTaskFileChange({
      setLocalFile,
      setUrlExternal,
      abortUploads
    })

  const hasAnyError = !!(errorUploadLocalFile || errorCreateTask)

  const progress = useFakeProgress({
    hasError: hasAnyError,
    start: activeStepIndex === 1,
    completed: activeStepIndex === 1 && !!taskCreatedId
  })

  const reset = useCallback(() => {
    if (errorUploadLocalFile || errorCreateTask) {
      resetAllUploads()
    }

    resetNavigation()
  }, [errorCreateTask, errorUploadLocalFile, resetAllUploads, resetNavigation])

  useRedirectOnTaskCreated({
    progress,
    taskCreatedId,
    playlistId,
    type: 'denoiser'
  })

  return {
    // navigation flow
    activeStepIndex,
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,

    // file or url input
    localFile,
    urlExternal,
    onSelectLocalFile,
    isExternalUrlValid,
    externalUrlErrorCode,
    onSetExternalUrl,

    // upload state control
    loadingUploadTaskFile: loadingUploadLocalFile,
    errorUploadTaskFile: errorUploadLocalFile,
    taskFileUploaded: resultUploadLocalFile,

    // create task state control
    loadingCreateTask,
    errorCreateTask,
    taskCreatedId,

    // any error on file upload or task creation
    hasAnyError,

    // reset the whole process
    reset,

    // task creation fake progress
    progress
  }
}
