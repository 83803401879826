import { gql } from 'graphql-request'

interface SubscriptionCli {
  toggleAutoRenewSubscription(): Promise<any>
}

const Subscription = (graphQL: any): SubscriptionCli => {
  const toggleAutoRenewSubscription = async (): Promise<any> => {
    const query = gql`
      mutation toggleSubscriptionAutoRenew {
        toggleSubscriptionAutoRenew
      }
    `
    const result = await graphQL({ query })
    return result
  }

  return {
    toggleAutoRenewSubscription
  }
}

export default Subscription
