import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './alert-container.module.scss'

export const AlertContainer: React.FC = () => {
  const { i18n } = useLingui()
  const [STORAGE_BANNER] = useState('moises:close-floating-banner')
  const [show, setShow] = useState(false)

  const onClose = useCallback(() => {
    setShow(false)
    sessionStorage.setItem(STORAGE_BANNER, 'true')
  }, [STORAGE_BANNER])

  useEffect(() => {
    const userCloseBanner = sessionStorage.getItem(STORAGE_BANNER)

    if (!userCloseBanner) {
      setShow(true)
    }
  }, [STORAGE_BANNER])

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.hide]: !show
        })}
      >
        <div className={styles.text}>
          <p>
            <span // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18n
                  ._(t`browser_banner`)
                  .replace(
                    /\*(Chrome)\*/g,
                    `<a class="${styles.link}" target="_BLANK" href="https://chromeenterprise.google/browser/download/?utm_source=adwords&utm_medium=cpc&utm_campaign=2022-H2-chromebrowser-paidmed-paiddisplay-other-chromebrowserent&utm_term=downloadnow-chrome-browser-download&utm_content=GCEJ&brand=GCEJ&gclid=CjwKCAjwo_KXBhAaEiwA2RZ8hOh2m43_Nw4JceNloNDPFei1uCp9zc3GwqkkfmvPOSELJluGi2wb6BoClAUQAvD_BwE&gclsrc=aw.ds#windows-tab">$1</a>`
                  )
                  .replace(
                    /\*([^*]+)\*/g,
                    `<a class="${styles.link}" target="_BLANK" href="https://desktop.moises.ai/">$1</a>`
                  )
              }}
            />
          </p>
        </div>

        <button type="button" className={styles.button} onClick={onClose}>
          <Icon name="close" />
        </button>
      </div>
    </>
  )
}
