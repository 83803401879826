import { useCallback } from 'react'
import { useFakeProgress } from '../../misc/use-fake-progress'
import {
  useTaskFile,
  useTaskFileChange,
  useTaskUpload
} from '../use-create-task/common'
import { useRedirectOnTaskCreated } from '../use-redirect-on-task-created'
import { UseCreateMasterTask } from './types'
import { useSubmitMasterTask } from './use-submit-master-task'
import { useTaskConfig } from './use-task-config'
import { useTaskNavigation } from './use-task-navigation'

interface UseCreateMasterTaskProps {
  playlistId: null | string
}

export const useCreateMasterTask = ({
  playlistId
}: UseCreateMasterTaskProps): UseCreateMasterTask => {
  // first file
  const {
    localFile,
    setLocalFile,
    urlExternal,
    setUrlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,
    taskFile,
    setTaskFile
  } = useTaskFile()

  // reference file
  const {
    localFile: localFileReference,
    setLocalFile: setLocalFileReference,
    urlExternal: urlExternalReference,
    setUrlExternal: setUrlExternalReference,
    isExternalUrlValid: isExternalUrlReferenceValid,
    externalUrlErrorCode: externalUrlReferenceErrorCode,
    fileName: fileNameReference,
    setFileName: onSetFileNameReference,
    taskFile: taskFileReference,
    setTaskFile: setTaskFileReference
  } = useTaskFile()

  const { masterConfig, onSetMasterConfig } = useTaskConfig()

  const {
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,
    resetNavigation,
    activeStepIndex
  } = useTaskNavigation({
    localFile,
    localFileReference,
    isExternalUrlValid,
    isExternalUrlReferenceValid,
    masterConfig
  })

  // first file or url upload state
  const {
    // external url
    loadingUploadExternalUrl,
    errorUploadExternalUrl,
    resultUploadExternalUrl,
    // local file
    loadingUploadLocalFile,
    errorUploadLocalFile,
    resultUploadLocalFile,
    // abort
    abortUploads
  } = useTaskUpload({
    localFile,
    setTaskFile,
    urlExternal,
    isExternalUrlValid
  })

  // reference file or url upload state
  const {
    // external url
    loadingUploadExternalUrl: loadingUploadExternalUrlReference,
    errorUploadExternalUrl: errorUploadExternalUrlReference,
    resultUploadExternalUrl: resultUploadExternalUrlReference,
    // local file
    loadingUploadLocalFile: loadingUploadLocalFileReference,
    errorUploadLocalFile: errorUploadLocalFileReference,
    resultUploadLocalFile: resultUploadLocalFileReference,
    // abort
    abortUploads: abortUploadsReference
  } = useTaskUpload({
    localFile: localFileReference,
    setTaskFile: setTaskFileReference,
    urlExternal: urlExternalReference,
    isExternalUrlValid: isExternalUrlReferenceValid,
    fileName: fileNameReference,
    isFileNameRequired: true
  })

  const { loadingCreateTask, errorCreateTask, taskCreatedId } =
    useSubmitMasterTask({
      taskFile,
      taskFileReference,
      masterConfig,
      activeStepIndex
    })

  const { onSelectLocalFile, onSetExternalUrl, resetAllUploads } =
    useTaskFileChange({
      setLocalFile,
      setUrlExternal,
      abortUploads
    })

  const {
    onSelectLocalFile: onSelectLocalFileReference,
    onSetExternalUrl: onSetExternalUrlReference,
    resetAllUploads: resetAllReferenceUploads
  } = useTaskFileChange({
    setLocalFile: setLocalFileReference,
    setUrlExternal: setUrlExternalReference,
    abortUploads: abortUploadsReference
  })

  const hasAnyError = !!(
    errorUploadLocalFile ||
    errorUploadExternalUrl ||
    errorUploadLocalFileReference ||
    errorUploadExternalUrlReference ||
    errorCreateTask
  )

  const progress = useFakeProgress({
    hasError: hasAnyError,
    start: activeStepIndex === 2,
    completed: activeStepIndex === 2 && !!taskCreatedId
  })

  const reset = useCallback(() => {
    if (errorUploadLocalFile || errorUploadExternalUrl || errorCreateTask) {
      resetAllUploads()
    }

    if (
      errorUploadLocalFileReference ||
      errorUploadExternalUrlReference ||
      errorCreateTask
    ) {
      resetAllReferenceUploads()
    }

    resetNavigation()
  }, [
    errorCreateTask,
    errorUploadLocalFile,
    errorUploadLocalFileReference,
    errorUploadExternalUrl,
    errorUploadExternalUrlReference,
    resetNavigation,
    resetAllUploads,
    resetAllReferenceUploads
  ])

  useRedirectOnTaskCreated({
    progress,
    taskCreatedId,
    playlistId,
    type: 'master'
  })

  return {
    // navigation flow
    activeStepIndex,
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,

    // file or url input
    localFile,
    urlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,

    onSetMasterConfig,

    onSelectLocalFile,
    onSetExternalUrl,
    onSelectLocalFileReference,
    onSetExternalUrlReference,
    onSetFileNameReference,

    // reference file or url input
    localFileReference,
    urlExternalReference,
    isExternalUrlReferenceValid,
    externalUrlReferenceErrorCode,
    fileNameReference,

    // task configuration
    masterConfig,

    // upload first file state control
    loadingUploadTaskFile: loadingUploadLocalFile || loadingUploadExternalUrl,
    errorUploadTaskFile: errorUploadLocalFile || errorUploadExternalUrl,
    taskFileUploaded: resultUploadLocalFile || resultUploadExternalUrl,

    // upload reference file state control
    loadingUploadTaskFileReference:
      loadingUploadLocalFileReference || loadingUploadExternalUrlReference,
    errorUploadTaskFileReference:
      errorUploadLocalFileReference || errorUploadExternalUrlReference,
    taskFileReferenceUploaded:
      resultUploadLocalFileReference || resultUploadExternalUrlReference,

    // create task state control
    loadingCreateTask,
    errorCreateTask,
    taskCreatedId,

    // task creation fake progress
    progress,
    reset,
    hasAnyError
  }
}
