import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ContextScala } from 'scala'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'

interface UseUnsubscribePlaylist {
  error?: boolean
  loading: boolean
  unsubcribePlaylist(i: { playlistId: string }): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useUnsubscribePlaylist = (): UseUnsubscribePlaylist => {
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const {
    userToken,
    toast: { add: addToast }
  } = useContext(Context)
  const { user } = useContext(ContextScala)

  const [state, unsubcribePlaylist] = useAsyncFn(
    async ({ playlistId }: { playlistId: string }) => {
      let result
      MoisesCLI.auth(userToken)

      try {
        result = await MoisesCLI.unsubscribeFromPlaylist({ playlistId })

        addToast({
          icon: null,
          closable: true,
          type: 'success',
          description: 'Successfully unsubscribed.'
        })

        if (pathname !== '/library') {
          push('/library/')
        }
      } catch (e: any) {
        addToast({
          icon: null,
          type: 'error',
          closable: true,
          title: i18n._(t`error.deleting.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      return result
    },
    [i18n, user, userToken, addToast, pathname, push]
  )

  const { loading, error } = state

  return {
    ...state,
    loading,
    error: !!error,
    unsubcribePlaylist
  }
}
