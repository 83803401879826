import classnames from 'classnames'
import React from 'react'
import styles from './grid-row.module.scss'
import { GridRowProps } from './grid-row.types'

export const GridRow: React.FC<GridRowProps> = ({
  className,
  children,
  reverse,
  space,
  justify,
  align,
  hideSmall,
  hideMedium,
  hideLarge,
  gutterless
}) => (
  <div
    className={classnames(className, styles.gridRow, {
      [styles.reverse]: reverse,
      [styles.spaceBetween]: space === 'between',
      [styles.spaceAround]: space === 'around',
      [styles.justifyLeft]: justify === 'left',
      [styles.justifyRight]: justify === 'right',
      [styles.justifyCenter]: justify === 'center',
      [styles.alignTop]: align === 'top',
      [styles.alignMiddle]: align === 'middle',
      [styles.alignBottom]: align === 'bottom',
      [styles.alignEqual]: align === 'equal',
      [styles.hideSmall]: hideSmall,
      [styles.hideMedium]: hideMedium,
      [styles.hideLarge]: hideLarge,
      [styles.gutterless]: gutterless
    })}
  >
    {children}
  </div>
)
