import { useCallback, useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { request } from 'graphql-request'
import { getUserQuery } from './get-user-query'

const getUserData = async (
  apiUrl: any,
  auth: any,
  clientHeaders: any
): Promise<any> => {
  if (!auth) return null

  const { user } = await request(apiUrl, getUserQuery, null, {
    authorization: auth,
    ...clientHeaders
  })

  return {
    user: {
      ...user,
      subscription: {
        ...user.subscription,
        active: user.subscription.isPremium
      }
    }
  }
}

type UseGetUserDataParams = {
  apiUrl: string
  auth: string | null
  clientHeaders: {
    'apollographql-client-name': string
    'apollographql-client-version': string
  }
}

export const useGetUserData = ({
  apiUrl,
  auth,
  clientHeaders
}: UseGetUserDataParams): any => {
  const [silentRetrying, setSilentRetrying] = useState(false)

  const { retry, loading, value, error } = useAsyncRetry(
    async () => getUserData(apiUrl, auth, clientHeaders),
    [apiUrl, auth, clientHeaders]
  )

  const silentRetry = useCallback(() => {
    setSilentRetrying(true)
    retry()
  }, [retry])

  useEffect(() => {
    // reset "silentRetrying" state after fetching the data
    if (loading === false && auth) {
      setSilentRetrying(false)
    }
  }, [loading, auth])

  return {
    value,
    error,
    retry,
    silentRetry,
    // bypass default loading state if "silentRetrying" is true
    loading: silentRetrying ? false : loading || !auth
  }
}
