import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { Context } from 'scala'
import { useFetchPlaylist } from '../../../hooks/playlist/use-fetch-playlist'
import { Track } from '../track/track'
import styles from './playlist-sample.module.scss'
import { HidePlaylistSampleButton } from './hide-playlist-sample-button'
import { useDemoPlaylistPreferences } from '../../../hooks/use-demo-playlist-preferences'
import { Context as ContextApp } from '../../../context'

interface PlaylistSampleProps {
  playlistId: string
  onClickTrack?(taskId: string, type?: string): void
}

export const PlaylistSample: React.FC<PlaylistSampleProps> = ({
  playlistId,
  onClickTrack
}) => {
  const router = useRouter()
  const { i18n } = useLingui()
  const { user } = useContext(Context)
  const { userToken } = useContext(ContextApp)
  const { playlist } = useFetchPlaylist({ playlistId })

  const { handleHide, shouldHide } = useDemoPlaylistPreferences({
    user,
    userToken
  })

  const handlePlaylistClick = useCallback(() => {
    router.push(`/setlist/${playlistId}`)
  }, [playlistId, router])

  if (!playlist || shouldHide) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.divider} />
        <div className={styles.or}>{i18n._(t`or_option`)}</div>
        <div className={styles.divider} />
      </div>

      <div className={styles.contents}>
        <p className={styles.description}>
          {i18n._(t`collection_discover_message`)}
          <span>&nbsp;</span>

          <button
            type="button"
            onClick={handlePlaylistClick}
            className={styles.playlistButton}
          >
            {i18n._(t`moises_collection`)}
          </button>
        </p>

        <HidePlaylistSampleButton onHide={handleHide} />
      </div>

      {playlist?.tracks?.edges.map((edge) => (
        <Track
          key={edge.id}
          className={styles.track}
          track={edge.node}
          onClick={onClickTrack}
        />
      ))}
    </div>
  )
}
