import { useCallback, useEffect, useState } from 'react'
import { useRedirectOnTaskCreated } from '..'
import { definitions } from '../../../data/operations-defs'
import { useFakeProgress } from '../../misc/use-fake-progress'
import { SeparationOption } from '../../use-default-separation'
import {
  useTaskFile,
  useTaskFileChange,
  useTaskUpload
} from '../use-create-task/common'
import { UseCreateSplitTask } from './types'
import { useSubmitSplitTask } from './use-submit-split-task'
import { useTaskConfig } from './use-task-config'
import { useTaskNavigation } from './use-task-navigation'

interface UseCreateSplitTaskProps {
  playlistId: null | string
  lastStepIndex: number
  defaultSeparationSelected?: SeparationOption
}

export const useCreateSplitTask = ({
  playlistId,
  lastStepIndex,
  defaultSeparationSelected
}: UseCreateSplitTaskProps): UseCreateSplitTask => {
  const {
    localFile,
    setLocalFile,
    urlExternal,
    setUrlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,
    taskFile,
    setTaskFile
  } = useTaskFile()

  const { splitConfig, onSetSplitConfig } = useTaskConfig()

  const [configDefined, setConfigDefined] = useState(false)

  useEffect(() => {
    if (configDefined || !defaultSeparationSelected?.key) {
      return
    }

    onSetSplitConfig(definitions[defaultSeparationSelected.key])
    setConfigDefined(true)
  }, [configDefined, defaultSeparationSelected, onSetSplitConfig])

  const {
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,
    resetNavigation,
    activeStepIndex
  } = useTaskNavigation({
    localFile,
    isExternalUrlValid,
    splitConfig,
    lastStepIndex
  })

  const {
    // external url
    loadingUploadExternalUrl,
    errorUploadExternalUrl,
    resultUploadExternalUrl,

    // local file
    loadingUploadLocalFile,
    errorUploadLocalFile,
    resultUploadLocalFile,

    abortUploads
  } = useTaskUpload({
    localFile,
    setTaskFile,
    urlExternal,
    isExternalUrlValid
  })

  const { loadingCreateTask, errorCreateTask, taskCreatedId } =
    useSubmitSplitTask({
      taskFile,
      splitConfig,
      activeStepIndex,
      lastStepIndex
    })

  const { onSelectLocalFile, onSetExternalUrl, resetAllUploads } =
    useTaskFileChange({
      setLocalFile,
      setUrlExternal,
      abortUploads
    })

  const hasAnyError = !!(
    errorUploadLocalFile ||
    errorUploadExternalUrl ||
    errorCreateTask
  )

  const progress = useFakeProgress({
    hasError: hasAnyError,
    start: activeStepIndex === lastStepIndex,
    completed: activeStepIndex === lastStepIndex && !!taskCreatedId
  })

  const reset = useCallback(() => {
    if (errorUploadLocalFile || errorUploadExternalUrl || errorCreateTask) {
      resetAllUploads()
    }

    resetNavigation()
  }, [
    errorCreateTask,
    errorUploadExternalUrl,
    errorUploadLocalFile,
    resetAllUploads,
    resetNavigation
  ])

  useRedirectOnTaskCreated({ progress, taskCreatedId, playlistId })

  return {
    // navigation flow
    activeStepIndex,
    moveForwardDisabled,
    canMoveForward,
    canMoveBackward,
    moveForward,
    moveBackward,

    // file or url input
    localFile,
    urlExternal,
    isExternalUrlValid,
    externalUrlErrorCode,
    onSelectLocalFile,
    onSetSplitConfig,
    onSetExternalUrl,

    // task configuration
    splitConfig,

    // upload state control
    loadingUploadTaskFile: loadingUploadLocalFile || loadingUploadExternalUrl,
    errorUploadTaskFile: errorUploadLocalFile || errorUploadExternalUrl,
    taskFileUploaded: resultUploadLocalFile || resultUploadExternalUrl,

    // create task state control
    loadingCreateTask,
    errorCreateTask,
    taskCreatedId,

    // any error on file upload or task creation
    hasAnyError,

    // reset the whole process
    reset,

    // task creation fake progress
    progress
  }
}
