import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React, { useEffect } from 'react'
import defaultLang from 'localization/data/localization/languages/en/common.json'

i18n.load({
  en: defaultLang
})

i18n.activate('en')

interface ContainerI18n {
  children: React.ReactNode
}

export const I18nContainer: React.FC<ContainerI18n> = ({ children }) => {
  useEffect(() => {
    i18n.activate(global.window.navigator.language)
  }, [])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
