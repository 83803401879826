import React from 'react'
import styles from './progress-circle.module.scss'
import { ProgressProps } from './progress-circle.types'

export const ProgressCircle: React.FC<ProgressProps> = ({ progress }) => {
  return (
    <div className={styles.progress}>
      <span className={styles.circularProgress} data-deg={progress}>
        <span className={styles.percent}>
          <span>
            {progress}
            <small>%</small>
          </span>
        </span>
      </span>
    </div>
  )
}
