import { TaskOperations } from '../../../types'

const hasOperationOfTypes = (
  types: string[],
  operations: TaskOperations[]
): boolean =>
  !!types.find((operationName: string) =>
    operations.find((operation) =>
      operation?.name.toLowerCase().includes(operationName)
    )
  )

const getTrackCurrentType = (operations: TaskOperations[]): string | null => {
  if (hasOperationOfTypes(['mastering'], operations)) {
    return 'mastering'
  }

  if (hasOperationOfTypes(['denoiser'], operations)) {
    return 'denoiser'
  }

  if (
    hasOperationOfTypes(
      ['separate', 'track-split', 'beat-chords', 'beatschords', 'bpmdetect'],
      operations
    )
  ) {
    return 'spliter'
  }

  return null
}

export const useTaskType = (operations?: TaskOperations[]): string | null => {
  if (!operations) {
    return null
  }

  const currentOperation = operations.find(({ current }) => current)

  if (!currentOperation) {
    return getTrackCurrentType(operations)
  }

  return getTrackCurrentType([currentOperation])
}
