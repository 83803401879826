import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context'
import styles from './alert-get-app.module.scss'

export const AlertGetApp: React.FC = () => {
  const { i18n } = useLingui()
  const [STORAGE_GET_APP] = useState('moises:close-banner-mobile-get-app')
  const [show, setShow] = useState(false)
  const {
    modalGetApp: { onGoGetApp }
  } = useContext(Context)

  useEffect(() => {
    const userCloseBanner = sessionStorage.getItem(STORAGE_GET_APP)

    if (!userCloseBanner) {
      setShow(true)
    }
  }, [STORAGE_GET_APP])

  return (
    <div
      className={classnames(styles.container, {
        [styles.hide]: !show
      })}
    >
      <button
        type="button"
        className={styles.buttonGetApp}
        onClick={onGoGetApp}
      >
        <Icon name="download-app" className={styles.icon} />
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18n
              ._(t`actions.getapp.full`)
              .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
          }}
        />
      </button>
    </div>
  )
}
