import { useCallback, useState } from 'react'
import { LocalFile } from '../use-create-task/common'
import { TaskConfig } from './types'

interface UseNavigationParams {
  isExternalUrlValid: boolean
  localFile: LocalFile
  splitConfig: TaskConfig | null
  lastStepIndex: number
}

interface UseNavigation {
  moveForwardDisabled: boolean
  canMoveBackward: boolean
  canMoveForward: boolean
  activeStepIndex: number
  resetNavigation(): void
  moveForward(): void
  moveBackward(): void
}

export const useTaskNavigation = ({
  isExternalUrlValid,
  localFile,
  splitConfig,
  lastStepIndex
}: UseNavigationParams): UseNavigation => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const resetNavigation = useCallback(() => {
    setActiveStepIndex(0)
  }, [])

  let moveForwardDisabled = true
  const canMoveBackward = activeStepIndex > 0
  const canMoveForward = activeStepIndex < lastStepIndex

  const moveForward = useCallback(
    () => setActiveStepIndex(activeStepIndex + 1),
    [activeStepIndex]
  )

  const moveBackward = useCallback(() => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1)
    }
  }, [activeStepIndex])

  const hasFileOrUrlInput = isExternalUrlValid || localFile
  const hasConfigTypeSet = !!splitConfig

  switch (activeStepIndex) {
    case 0:
      if (hasFileOrUrlInput) moveForwardDisabled = false
      break
    case 1:
      if (hasFileOrUrlInput && hasConfigTypeSet) moveForwardDisabled = false
      break
    default:
      moveForwardDisabled = true
  }

  return {
    canMoveForward,
    canMoveBackward,
    moveForwardDisabled,
    activeStepIndex,
    moveForward,
    moveBackward,
    resetNavigation
  }
}
