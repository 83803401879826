import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useContext } from 'react'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'

interface UseUpdatePlaylist {
  error?: boolean
  loading: boolean
  playlistUpdated: boolean
  updatePlaylist(i: {
    name: string
    description: string | null
    playlistId: string
  }): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useUpdatePlaylist = (): UseUpdatePlaylist => {
  const { i18n } = useLingui()
  const {
    userToken,
    toast: { add: addToast }
  } = useContext(Context)

  const [state, updatePlaylist] = useAsyncFn(
    async ({
      name,
      description,
      playlistId
    }: {
      name: string
      description: string | null
      playlistId: string
    }) => {
      let result
      MoisesCLI.auth(userToken)

      try {
        result = await MoisesCLI.updatePlaylist({
          playlistId,
          name,
          description
        })

        addToast({
          icon: null,
          closable: true,
          type: 'success',
          description: i18n._(t`setlist_update_success`)
        })
      } catch (e: any) {
        addToast({
          icon: null,
          type: 'error',
          closable: true,
          title: i18n._(t`error.editing.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      return result
    },
    [userToken, addToast]
  )

  const { loading, error, value } = state

  return {
    loading,
    error: !!error,
    playlistUpdated: value === true,
    updatePlaylist
  }
}
