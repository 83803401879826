import React, { useCallback, useContext, useEffect } from 'react'
import { Context, Icon, TooltipWrapper } from 'scala'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import { ModalConfirmation } from '../../modal-confirmation'
import styles from './playlist-sample.module.scss'
import { useToggle } from '../../../hooks/misc/use-toggle'
import { useEvent } from '../../../hooks/analytics'

interface HidePlaylistSampleButtonProps {
  onHide(): void
}

export const HidePlaylistSampleButton: React.FC<
  HidePlaylistSampleButtonProps
> = ({ onHide }) => {
  const { user } = useContext(Context)
  const { i18n } = useLingui()
  const { sendEvent } = useEvent()
  const {
    value: isModalOpen,
    toggleTrue: handleOpenModal,
    toggleFalse: handleCloseModal
  } = useToggle(false)

  const handleConfirmHide = useCallback(() => {
    onHide()
    handleCloseModal()
    sendEvent({
      userId: user.id,
      name: 'hide_moises_collection_finalized',
      category: 'engagement'
    })
  }, [handleCloseModal, onHide, sendEvent, user.id])

  useEffect(() => {
    if (isModalOpen) {
      sendEvent({
        userId: user.id,
        name: 'hide_moises_collection',
        category: 'engagement'
      })
    }
  }, [isModalOpen, sendEvent, user.id])

  return (
    <>
      <TooltipWrapper top label={i18n._(t`hide_button`)}>
        <button
          type="button"
          onClick={handleOpenModal}
          className={styles.hideButton}
        >
          <Icon name="no-eye" width={20} height={20} />
        </button>
      </TooltipWrapper>

      <ModalConfirmation
        isOpen={isModalOpen}
        onDismiss={handleCloseModal}
        title={i18n._(t`hide_demo_playlist`)}
        info={i18n._(t`hide_message`)}
        confirmLabel={i18n._(t`hide_button`)}
        cancelLabel={i18n._(t`keep_showing_button`)}
        onConfirm={handleConfirmHide}
        shouldCloseOnOverlayClick
      />
    </>
  )
}
