import classnames from 'classnames'
import React from 'react'
import styles from './form-checkbox.module.scss'

interface FormCheckboxProps {
  active?: boolean
  className?: string
}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  active,
  className
}) => (
  <div
    className={classnames(styles.checkbox, className, {
      [styles.active]: active
    })}
  />
)
