import { useEffect } from 'react'
import { TaskMasteringParams, useCreateTask } from '../use-create-task'
import { TaskFile, TaskStep } from '../use-create-task/common'
import { TaskConfig } from './types'

interface UseCreateTaskMasterParams {
  taskFile: TaskFile
  taskFileReference: TaskFile
  masterConfig: TaskConfig
  activeStepIndex: TaskStep
}

interface UseCreateTaskMaster {
  loadingCreateTask: boolean
  errorCreateTask: Error | undefined
  taskCreatedId: string | undefined
}

export const useSubmitMasterTask = ({
  taskFile,
  taskFileReference,
  masterConfig,
  activeStepIndex
}: UseCreateTaskMasterParams): UseCreateTaskMaster => {
  const {
    loading: loadingCreateTask,
    error: errorCreateTask,
    value: taskCreatedId,
    createTask
  } = useCreateTask()

  // automatically submit the task when all required conditions are met
  useEffect(() => {
    if (
      !taskFile?.tempLocation ||
      !taskFileReference?.tempLocation ||
      !masterConfig ||
      activeStepIndex !== 2
    ) {
      return
    }

    const separateParams: TaskMasteringParams = {
      referenceAudio: taskFileReference,
      bit: masterConfig.bit,
      limiter: masterConfig.limiter,
      normalize: masterConfig.normalize,
      targetLufs: masterConfig.targetLufs
    }

    createTask({
      file: taskFile,
      operations: [
        {
          id: 'MASTERING_A',
          params: separateParams
        }
      ]
    })
  }, [activeStepIndex, createTask, taskFile, taskFileReference, masterConfig])

  const requiredConditions =
    taskFile?.tempLocation &&
    taskFileReference?.tempLocation &&
    masterConfig &&
    activeStepIndex === 2

  return {
    loadingCreateTask,
    errorCreateTask:
      errorCreateTask && requiredConditions ? errorCreateTask : undefined,
    taskCreatedId
  }
}
