import classnames from 'classnames'
import React from 'react'
import styles from './grid-column.module.scss'
import { GridColumnProps } from './grid-column.types'

export const GridColumn: React.FC<GridColumnProps> = ({
  className,
  children,
  large = 0,
  small = 0,
  medium = 0,
  fluid = 0,
  hideSmall = false,
  hideMedium = false,
  hideLarge = false,
  smart = false
}) => (
  <div
    className={classnames(
      className,
      styles.gridColumn,
      styles[`col-fluid-${fluid}`],
      styles[`col-small-${small}`],
      styles[`col-medium-${medium}`],
      styles[`col-large-${large}`],
      {
        [styles.hideSmall]: hideSmall,
        [styles.hideMedium]: hideMedium,
        [styles.hideLarge]: hideLarge,
        [styles.smart]: smart
      }
    )}
  >
    {children}
  </div>
)
