import { FirebaseApp, Moises } from '@moises-ai/sdk'
import { config, getClientHeaders } from '../../config'

export const moisesSDK = new Moises({
  apiEndpoint: config.api.endpoint,
  urlDownloadEndpoint: config.urlDownload.endpoint,
  headers: {
    ...getClientHeaders()
  },
  recaptchaToken: config.firebase.recaptchaToken,
  firebase: {
    appId: config.firebase.auth.appId,
    apiKey: config.firebase.auth.apiKey,
    authDomain: config.firebase.auth.authDomain,
    databaseURL: config.firebase.auth.databaseURL,
    projectId: config.firebase.auth.projectId,
    storageBucket: config.firebase.auth.storageBucket,
    messagingSenderId: config.firebase.auth.messagingSenderId
  }
})

export const FirebaseAuth = FirebaseApp.auth()

export { FirebaseApp }
