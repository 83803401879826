import React from 'react'
import classnames from 'classnames'
import { ErrorBarProps } from '../../types'
import { Icon } from '../icon'

import styles from './error-bar.module.scss'

export const ErrorBar: React.FC<ErrorBarProps> = ({ className, title }) => (
  <div className={classnames(className, styles.errorBar)}>
    <p className={styles.label}>{title}</p>
    <div className={styles.iconContainer}>
      <Icon name="alert" className={styles.icon} />
    </div>
  </div>
)
