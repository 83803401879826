import { useCallback, useState } from 'react'
import { LocalFile } from '../use-create-task/common'

interface UseNavigationParams {
  localFile: LocalFile
}

interface UseNavigation {
  moveForwardDisabled: boolean
  canMoveBackward: boolean
  canMoveForward: boolean
  activeStepIndex: number
  resetNavigation(): void
  moveForward(): void
  moveBackward(): void
}

export const useTaskNavigation = ({
  localFile
}: UseNavigationParams): UseNavigation => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const resetNavigation = useCallback(() => {
    setActiveStepIndex(0)
  }, [])

  let moveForwardDisabled = true
  const canMoveBackward = activeStepIndex === 1
  const canMoveForward = activeStepIndex < 2

  const moveForward = useCallback(
    () => setActiveStepIndex(activeStepIndex + 1),
    [activeStepIndex]
  )

  const moveBackward = useCallback(() => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1)
    }
  }, [activeStepIndex])

  const hasFileOrUrlInput = localFile

  switch (activeStepIndex) {
    case 0:
      if (hasFileOrUrlInput) moveForwardDisabled = false
      break
    default:
      moveForwardDisabled = true
  }

  return {
    canMoveForward,
    canMoveBackward,
    moveForwardDisabled,
    activeStepIndex,
    moveForward,
    moveBackward,
    resetNavigation
  }
}
