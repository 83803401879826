import { useCallback, useEffect } from 'react'
import { useUploadExternalUrl } from '../../../upload/external-url'
import { useUploadLocalFile } from '../../../upload/local-file'
import { TaskFileUploaded } from '../use-create-task'
import { FileURL, LocalFile } from './types'

interface UseTaskUploadParams {
  localFile: LocalFile
  setTaskFile(file: TaskFileUploaded): void
  urlExternal: FileURL
  isExternalUrlValid: boolean
  fileName?: string | null
  isFileNameRequired?: boolean
}

interface UseTaskUpload {
  loadingUploadExternalUrl: boolean
  errorUploadExternalUrl?: Error
  resultUploadExternalUrl: TaskFileUploaded | null
  loadingUploadLocalFile: boolean
  errorUploadLocalFile?: Error
  resultUploadLocalFile: TaskFileUploaded | null
  abortUploads(): void
}

export const useTaskUpload = ({
  localFile,
  urlExternal,
  setTaskFile,
  isExternalUrlValid,
  fileName,
  isFileNameRequired
}: UseTaskUploadParams): UseTaskUpload => {
  const {
    loading: loadingUploadExternalUrl,
    error: errorUploadExternalUrl,
    value: resultUploadExternalUrl,
    uploadExternalUrl,
    cancelExternalUrlUploadSource
  } = useUploadExternalUrl()

  const {
    loading: loadingUploadLocalFile,
    error: errorUploadLocalFile,
    value: resultUploadLocalFile,
    uploadLocalFile,
    cancelLocalFileUploadSource
  } = useUploadLocalFile()

  const runUpload = useCallback(
    async ({
      type,
      input,
      title
    }: {
      type: string
      input: LocalFile | FileURL
      title?: string | null
    }) => {
      let result

      if (type === 'external') {
        await uploadExternalUrl(null) // reset current uploaded value
        result = await uploadExternalUrl(input, title)
      } else if (type === 'local') {
        await uploadLocalFile(null) // reset current uploaded value
        result = await uploadLocalFile(input)
      }

      setTaskFile(result)
    },
    [setTaskFile, uploadExternalUrl, uploadLocalFile]
  )

  const abortUploads = useCallback(() => {
    if (cancelLocalFileUploadSource) {
      cancelLocalFileUploadSource.cancel('gracefully abort local file upload')
    }

    if (cancelExternalUrlUploadSource) {
      cancelExternalUrlUploadSource.cancel(
        'gracefully abort external url upload'
      )
    }
  }, [cancelLocalFileUploadSource, cancelExternalUrlUploadSource])

  useEffect(() => {
    if (isFileNameRequired && (fileName === null || fileName === undefined)) {
      return
    }

    // automatically upload external url when a valid one is set
    runUpload({
      type: 'external',
      input: isExternalUrlValid ? urlExternal : null,
      title: fileName && fileName !== '' ? fileName : null
    })
  }, [runUpload, fileName, urlExternal, isExternalUrlValid, isFileNameRequired])

  useEffect(() => {
    // automatically upload local file when selected
    runUpload({
      type: 'local',
      input: localFile
    })
  }, [localFile, runUpload])

  return {
    // external url
    loadingUploadExternalUrl,
    errorUploadExternalUrl,
    resultUploadExternalUrl,

    // local file
    loadingUploadLocalFile,
    errorUploadLocalFile,
    resultUploadLocalFile,

    // general
    abortUploads
  }
}
