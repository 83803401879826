import { gql } from 'graphql-request'

interface CampaignCli {
  getGlobalCampaign(): Promise<any>
  getCampaignIndividual(couponCode: string): Promise<any>
}

const Campaign = (graphQL: any): CampaignCli => {
  const getGlobalCampaign = async (): Promise<any> => {
    const variables = {}
    const query = gql`
      query campaign {
        campaign {
          name
          couponCode
          discountPercentage
          eligiblePlans
          endAt
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const getCampaignIndividual = async (couponCode: string): Promise<any> => {
    const variables = {
      couponCode
    }
    const query = gql`
      query campaign($couponCode: String!) {
        campaign(couponCode: $couponCode) {
          name
          couponCode
          discountPercentage
          eligiblePlans
          endAt
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    getGlobalCampaign,
    getCampaignIndividual
  }
}

export default Campaign
