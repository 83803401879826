import { AppProps } from 'next/app'
import React, { ReactElement } from 'react'
import {
  AuthenticatedContainer,
  I18nContainer,
  RootContainer,
  ToastContainer
} from '../containers'
import { ContextProvider } from '../context'
import '../styles/styles.scss'

const MyApp = ({ Component, pageProps, router }: AppProps): ReactElement => {
  const includeTags = router.route !== '/playlist/invite/[token]'

  return (
    <ContextProvider>
      <I18nContainer>
        <RootContainer includeTags={includeTags}>
          <AuthenticatedContainer>
            <>
              <ToastContainer />
              <Component {...pageProps} />
            </>
          </AuthenticatedContainer>
        </RootContainer>
      </I18nContainer>
    </ContextProvider>
  )
}

export default MyApp
