import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import styles from './select.module.scss'
import { Option, SelectProps } from './select.types'

export const Select: React.FC<SelectProps> = ({
  className,
  title,
  options,
  defaultValue = '',
  controlledValue = null,
  customValue = false,
  disabled = false,
  hideArrow = false,
  onChange
}) => {
  const [value, setValue] = useState(defaultValue)

  const handleChange = useCallback(
    (e: any) => {
      if (disabled) {
        return
      }
      setValue(e.currentTarget.value)

      if (onChange) {
        onChange(e.currentTarget.value)
      }
    },
    [onChange, disabled]
  )

  return (
    <div className={classnames(styles.container, className)}>
      {title && <p className={styles.label}>{title}</p>}

      <div className={styles.drop}>
        <div className={styles.selectContainer}>
          <select
            onChange={handleChange}
            className={styles.select}
            value={controlledValue || value}
          >
            {options.map((option: Option) => (
              <option value={option.value} key={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {!hideArrow ? (
            <Icon className={styles.icon} name="arrow-fill" />
          ) : null}
        </div>

        {customValue ? (
          <input
            type="number"
            onChange={handleChange}
            value={controlledValue || value}
            className={styles.customValue}
          />
        ) : null}
      </div>
    </div>
  )
}
