import { Player } from '@lottiefiles/react-lottie-player'
import classnames from 'classnames'
import React from 'react'
import playerSource from './player-source'
import styles from './progress-upload.module.scss'
import { ProgressProps } from './types'

export const ProgressUpload: React.FC<ProgressProps> = ({
  className,
  progress
}) => (
  <div className={classnames(className, styles.progress)}>
    <Player loop autoplay src={playerSource} className={styles.player} />
    <span className={styles.percent}>
      <p>
        {progress}
        <small>%</small>
      </p>
    </span>
  </div>
)
