import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Icon, Modal } from 'scala'
import React from 'react'
import { ProgressUpload } from '../progress-upload'
import styles from './upload-progress.module.scss'
import { UploadProgressProps } from './upload-progress.types'

export const UploadProgress: React.FC<UploadProgressProps> = ({
  show,
  progress,
  error,
  reset
}) => {
  const { i18n } = useLingui()

  return (
    <div className={styles.container}>
      <Modal isOpen={show} label="creating task" targetId="moises-app">
        {error ? (
          <div className={styles.progress}>
            <div className={styles.progressError}>
              <Icon name="no-upload" width={46} height={46} />
            </div>

            <p className={styles.title}>
              {i18n._(t`upload.uploading.error.title`)}
            </p>
            <p className={styles.description}>
              {i18n._(t`upload.uploading.error.description`)}
            </p>

            <Button
              light
              small
              radius={1}
              onClick={reset}
              title={i18n._(t`actions.tryagain`)}
              className={styles.button}
              icon={<Icon name="refresh" />}
            />
          </div>
        ) : (
          <div className={styles.progress}>
            <ProgressUpload progress={progress} />

            <p className={styles.title}>
              {i18n._(t`upload.uploading.title`)}...
            </p>
            <p className={styles.description}>
              {i18n._(t`upload.uploading.description`)}
            </p>
          </div>
        )}
      </Modal>
    </div>
  )
}
