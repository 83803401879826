import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon, Key, Modal } from 'scala'
import { useContext } from 'react'
import { Context } from '../../context'
import styles from './modal-keyboard-shortcuts.module.scss'

interface GroupTitleProps {
  children: React.ReactNode
}

const GroupProps: React.FC<GroupTitleProps> = ({ children }) => (
  <h2 className={styles.contextName}>{children}</h2>
)

interface ListItemProps {
  text: string
  children: React.ReactNode
}

const ListItem: React.FC<ListItemProps> = ({ text, children }) => (
  <li className={styles.item}>
    <p className={styles.name}>{text}</p>
    {children}
  </li>
)

export const ModalKeyboardShortcuts: React.FC = () => {
  const { i18n } = useLingui()
  const {
    modalShortcutsMenu: { show, onDismiss }
  } = useContext(Context)

  if (!show) return null

  return (
    <Modal
      label="Keyboard shortcuts"
      targetId="moises-app"
      isOpen={show}
      onClose={onDismiss}
      shouldCloseOnOverlayClick
    >
      <div className={styles.commandsList}>
        <h1 className={styles.title}>{i18n._(t`keyboard_shortcuts`)}</h1>
        <button
          className={styles.close}
          type="button"
          onClick={onDismiss}
          tabIndex={0}
        >
          <Icon name="close" />
        </button>

        <div className={styles.listContext}>
          <GroupProps>{i18n._(t`mixerTitle`)}</GroupProps>
          <ul>
            <ListItem text={i18n._(t`play_pause`)}>
              <Key.Spacebar />
            </ListItem>
            <ListItem text={i18n._(t`rewind_sec`)}>
              <Key.Left />
            </ListItem>
            <ListItem text={i18n._(t`fast_forward_sec`)}>
              <Key.Right />
            </ListItem>
            <ListItem text={i18n._(t`volume_up`)}>
              <Key.Up />
            </ListItem>
            <ListItem text={i18n._(t`volume_down`)}>
              <Key.Down />
            </ListItem>
            <ListItem text={i18n._(t`song_beginning`)}>
              <Key.Zero />
            </ListItem>
            <ListItem text="Loop on/off">
              <Key.C />
            </ListItem>
            <ListItem text="Repeat Song on/off">
              <Key.R />
            </ListItem>
          </ul>
        </div>
      </div>
    </Modal>
  )
}
