import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React from 'react'
import { Step, StepsProps } from '../../../../types'
import { CarouselStepsFooter } from '../carousel-steps-footer'
import { CarouselStepsHeader } from '../carousel-steps-header'
import styles from './carousel-steps.module.scss'

export const CarouselSteps: React.FC<StepsProps> = ({
  beta,
  title,
  description,
  tooltip,
  footerInfo,
  steps,
  activeStepIndex,
  canMoveBackward,
  moveBackward,
  moveForward,
  canMoveForward,
  moveForwardDisabled,
  lastStepIndex = 2,
  showStepPreferences,
  defaultSeparationSelected,
  defaultSeparationEnabled,
  onShowSeparationType
}) => {
  const { i18n } = useLingui()
  const isLastStep = steps.length - 2 === activeStepIndex

  return (
    <section className={styles.container}>
      <CarouselStepsHeader
        beta={beta}
        title={title}
        description={description}
        tooltip={tooltip}
        steps={steps}
        activeStepIndex={activeStepIndex}
      />

      <main className={styles.main}>
        {steps?.map((step: Step, index: number) => (
          <div
            key={step?.title}
            className={classnames(styles.step, {
              [styles.show]:
                activeStepIndex === index ||
                (steps.length - lastStepIndex === index &&
                  activeStepIndex > index)
            })}
          >
            {step.component}
          </div>
        ))}
      </main>

      <CarouselStepsFooter
        labelNext={isLastStep ? i18n._(t`submit`) : i18n._(t`label.next`)}
        labelPrevious={i18n._(t`label.previous`)}
        footerInfo={footerInfo}
        canMoveBackward={canMoveBackward}
        moveBackward={moveBackward}
        moveForward={moveForward}
        canMoveForward={canMoveForward}
        moveForwardDisabled={moveForwardDisabled}
        showStepPreferences={showStepPreferences}
        onShowSeparationType={onShowSeparationType}
        defaultSeparationSelected={defaultSeparationSelected}
        defaultSeparationEnabled={!!defaultSeparationEnabled}
        isLastStep={isLastStep}
      />
    </section>
  )
}
