import { useCallback, useState } from 'react'
import { useInterval } from 'react-use'

export const useFakeProgress = ({
  start,
  hasError,
  completed
}: {
  hasError: boolean
  start: boolean
  completed: boolean
}): number => {
  const [progress, setProgress] = useState(0)

  const getDelay = useCallback(() => {
    if (!start || progress >= 100) return null
    if (completed && progress !== 100) return 25
    return 1000
  }, [start, progress, completed])

  useInterval(() => {
    if (hasError) setProgress(0)
    else {
      let newProgress = progress + 1

      if (!completed && progress === 99) newProgress = 99

      setProgress(newProgress)
    }
  }, getDelay())

  return progress
}
