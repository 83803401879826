import classnames from 'classnames'
import React from 'react'
import styles from './header-section-upload.module.scss'
import { HeaderSectionProps } from './types'

export const HeaderSectionUpload: React.FC<HeaderSectionProps> = ({
  title,
  icon,
  onClick,
  active,
  className
}) => {
  return (
    <div
      className={classnames(className, styles.container, {
        [styles.isButton]: !!onClick
      })}
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={onClick ? 0 : -1}
      role="button"
    >
      <p className={styles.title}>{title}</p>

      {icon ? (
        <span
          className={classnames(styles.icon, {
            [styles.active]: active
          })}
        >
          {icon}
        </span>
      ) : null}
    </div>
  )
}
