export interface UseIsExternalUrlValid {
  error: string | null
  isValid: boolean
}

export const useIsExternalUrlValid = (url: string): UseIsExternalUrlValid => {
  const streamingUrlsRegexes = [
    'open.spotify.com',
    'deezer.com',
    'deezer.page.link',
    'tidal.com',
    'music.apple.com',
    'vimeo.com',
    'music.amazon',
    'youtube',
    'youtu.be'
  ].map(
    (streamingUrl) =>
      new RegExp(`(http(s)?://)?(www.)?(${streamingUrl}).*`, 'g')
  )

  let error = null
  const isStreamingURL = !!streamingUrlsRegexes.find((regex) => regex.test(url))
  const isValidURL =
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url
    )

  if (isStreamingURL) {
    error = 'invalid-streaming-service'
  }

  if (!isValidURL) {
    error = 'invalid-url'
  }

  return {
    error,
    isValid: !error
  }
}
