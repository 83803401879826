import React from 'react'
import classnames from 'classnames'

import { LoadingProps } from '../../types'

import styles from './loading.module.scss'

const SkeletonProfile: React.FC = () => (
  <div className={styles.profile}>
    <span className={styles.profileAvatar} />
    <span className={styles.profileFill} />
  </div>
)

const SkeletonTasks: React.FC = () => (
  <div className={styles.library}>
    <div className={styles.libraryTopLine}>
      <span className={styles.libraryXs} />
      <span className={styles.libraryMd} />
    </div>
    {[0, 1, 2, 3, 4].map((index) => (
      <div
        key={`loading-${index}`}
        className={classnames(styles.libraryLine, {
          [styles.useBackground]: index === 0
        })}
      >
        <span className={styles.libraryXs} />
        <span className={styles.libraryMd} />
        <span className={styles.libraryLg} />
        <span className={styles.libraryMin} />
        <span className={styles.libraryMin} />
      </div>
    ))}
  </div>
)

const SkeletonTasksV2: React.FC<{ search?: boolean }> = ({ search }) => (
  <div className={styles.libraryV2}>
    {search && (
      <div className={styles.libraryV2TopLine}>
        <span className={styles.libraryV2TopLineMd} />
      </div>
    )}
    {[0, 1, 2, 3, 4].map((index) => (
      <div key={`loading-${index}`} className={styles.libraryV2Line}>
        <span className={styles.libraryV2LineXs} />
        <span className={styles.libraryV2LineLg} />
        <span className={styles.libraryV2LineMin} />
      </div>
    ))}
  </div>
)

const SkeletonSidebar: React.FC = () => (
  <div className={styles.sidebar}>
    <span className={styles.sidebarLg} />
    <span className={styles.sidebarXs} />
    {[0, 1, 2, 3, 4].map((index) => (
      <div key={`loading-${index}`} className={styles.sidebarLine}>
        <span className={styles.sidebarLineXs} />
        <span className={styles.sidebarLineLg} />
      </div>
    ))}
  </div>
)

const LoadingDefault: React.FC = () => (
  <div className={styles.loadingDefault}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

const SkeletonTrackModified: React.FC<{ full?: boolean }> = ({ full }) => (
  <div className={styles.trackModified}>
    <span className={styles.title} />

    <div className={styles.header}>
      <div className={styles.col}>
        <span className={styles.avatar} />
        <span className={styles.fillLarge} />
      </div>
      <div className={styles.col}>
        <span className={styles.fillSmall} />
        <span className={styles.fillMedium} />
      </div>
    </div>

    <div className={styles.switch}>
      <span className={styles.fill} />
    </div>

    <div className={styles.content}>
      <span className={styles.fill} />
    </div>

    {full ? (
      <div className={styles.footer}>
        <div className={styles.col}>
          <span className={styles.fillMedium} />
          <span className={styles.fillLarge} />
        </div>
        <div className={styles.columns}>
          {[0, 1, 2].map((index) => (
            <div key={`loading-${index}`} className={styles.col}>
              <span className={styles.fillMedium} />
              <span className={styles.fillSmall} />
            </div>
          ))}
        </div>
      </div>
    ) : null}
  </div>
)

const SkeletonTrackSplit: React.FC = () => (
  <div className={styles.trackSplit}>
    <span className={styles.title} />

    <div className={styles.header}>
      <div className={styles.col}>
        <span className={styles.avatar} />
        <span className={styles.fillLarge} />
      </div>
      <div className={styles.col}>
        <span className={styles.fillSmall} />
        <span className={styles.fillMedium} />
      </div>
    </div>

    <div className={styles.content}>
      {[0, 1, 2, 3].map((index) => (
        <span key={`loading-${index}`} className={styles.fill} />
      ))}
    </div>
  </div>
)

const SkeletonPlaylist: React.FC = () => (
  <div className={styles.playlist}>
    <div className={styles.line}>
      <div className={styles.col}>
        <div>
          <span className={styles.fillMedium} />
          <span className={styles.fillSmall} />
        </div>
      </div>
      <div className={styles.col}>
        <span className={styles.fillMedium} />
      </div>
    </div>

    <div className={styles.content}>
      {[0, 1, 2, 3].map((index) => (
        <span key={`loading-${index}`} className={styles.fillTrack} />
      ))}
    </div>

    <div className={styles.line2}>
      <span className={styles.fillThin} />
      <span className={styles.fillMedium} />
    </div>
  </div>
)

const SkeletonPlaylistCreate: React.FC = () => (
  <div className={styles.playlist}>
    <div className={styles.line}>
      <div className={styles.col}>
        <div>
          <span className={styles.fillMedium} />
          <span className={styles.fillSmall} />
        </div>
      </div>
      <div className={styles.col}>
        <span className={styles.fillMedium} />
      </div>
    </div>

    <div className={styles.create}>
      <span className={styles.fillLarge} />
      <span className={styles.fillMedium} />
      <span className={styles.fillSmall} />
    </div>

    <div className={styles.line2}>
      <span className={styles.fillThin} />
      <span className={styles.fillMedium} />
    </div>
  </div>
)

export const Loading: React.FC<LoadingProps> = ({ className, type }) => (
  <div className={className}>
    {(() => {
      switch (type) {
        case 'skeleton-profile':
          return <SkeletonProfile />
        case 'skeleton-tasks':
          return <SkeletonTasks />
        case 'skeleton-tasks-v2':
          return <SkeletonTasksV2 />
        case 'skeleton-tasks-v2-search':
          return <SkeletonTasksV2 search />
        case 'skeleton-sidebar':
          return <SkeletonSidebar />
        case 'skeleton-modified-track-full':
          return <SkeletonTrackModified full />
        case 'skeleton-modified-track':
          return <SkeletonTrackModified />
        case 'skeleton-split-track':
          return <SkeletonTrackSplit />
        case 'skeleton-playlist':
          return <SkeletonPlaylist />
        case 'skeleton-playlist-create':
          return <SkeletonPlaylistCreate />
        default:
          return <LoadingDefault />
      }
    })()}
  </div>
)
