import { useCallback, useMemo, useState } from 'react'
import { useEventListener } from '../misc/use-event-listener'

export interface UseModalShortcutsMenu {
  show: boolean
  onOpen(): void
  onDismiss(): void
}

export const useModalShortcutsMenu = (): UseModalShortcutsMenu => {
  const [show, setShow] = useState(false)
  const KEY_QUESTION_MARK = useMemo(() => '?', [])

  const onOpen = useCallback(() => {
    setShow(true)
  }, [])

  const onDismiss = useCallback(() => setShow(false), [])

  const onHandlerKeyDown = useCallback(
    (e: any) => {
      if (
        e.target.matches('textarea') ||
        e.target.matches('input') ||
        e.key !== KEY_QUESTION_MARK
      ) {
        return
      }

      e.preventDefault()
      onOpen()
    },
    [KEY_QUESTION_MARK, onOpen]
  )

  useEventListener('keydown', onHandlerKeyDown)

  return {
    show,
    onOpen,
    onDismiss
  }
}
