import { useCallback, useRef, useState } from 'react'

export interface UseFailedAuthentication {
  disableFetching: boolean
  increaseErrorCount(): void
}

export const useFailedAuthentication = (): UseFailedAuthentication => {
  const count = useRef(0)
  const [disableFetching, setDisableFetching] = useState(false)

  const increaseErrorCount = useCallback((): void => {
    count.current += 1

    if (count.current >= 4) {
      setDisableFetching(true)
    }
  }, [])

  return {
    disableFetching,
    increaseErrorCount
  }
}
